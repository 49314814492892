  <div class="carouseCustom" id="reportsBooks">
    <div class="row no-gutters reportBooks__bookSectionHeading">
      <div class="col-9 col-md-9">
        <span class="booksHeading reportBooks__rightHeading"> Books </span>
      </div>
      <div class="col-12 col-sm-12 col-md-3">
        <span class="reportBooks__rightSubHeading"><a href="/resources">View More</a></span>
      </div>
    </div>
    <div class="reportBooks__bookBox">
      <ngx-slick-carousel class="reportBooks__courosel carousel" 
      #slickModal="slick-carousel" 
      [config]="slideConfig" 
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">
        <div *ngFor="let books of bresults" class="slide">
          <div ngxSlickItem>
            <div class="row">
              <div class="col-6" *ngFor="let item of books ">
                <div class="reportBooks__bookBox__book">
                  <div>
                    <iframe  style="height: 240px;"  marginwidth="0" marginheight="0" scrolling="no" frameborder="0"
                    [src]="item.Books.safe_url">
                  </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>

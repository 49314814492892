import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StorageService } from '../../../services/storage.service';
import { IMAGE_CONSTANTS } from '../../imageConstants';
import { ROUTER_CONSTANATS } from '../../routerUrl.constants';
import { StudentService } from "./../../../modules/admin/student/student.service";
import { ExtractReportService } from '@app/services/extract-report.service';
@Component({
  selector: 'cf-reports-profile',
  templateUrl: './reports-profile.component.html',
  styleUrls: ['./reports-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsProfileComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  userName: string;
  userScore = '';
  notificationsCount: any;
  public enableSymbol:boolean = false;
  public showScore:boolean = false;
  userProfileCharacters = '';
  showShare: boolean;
  constructor(private studentService: StudentService, private storage: StorageService, private router: Router, private changeDetectorRef: ChangeDetectorRef, private report: ExtractReportService, private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    const myData = this.storage.getItem('userData');
    if (myData) {
      this.userName = myData?.first_name;
      this.userScore = myData?.reliabilityScore;
      if (this.userScore !== '' && this.userScore !== undefined && this.userScore !== null) {
        this.showScore = true;
      }
      console.log("reliability", this.userScore)
      console.log("test", this.showScore)
      this.activeRoute.paramMap.subscribe((params) => {
        if (params.get('id')) {
          const data = { student_id: myData.student_id, reportName: 'Cc_' + params.get('id') };
          this.report.extractReportPDF(data).subscribe((res: any) => {
            if (res.status === 1) {
              this.showScore = true;
              this.userScore = res.data?.report_info?.score;
              this.changeDetectorRef.detectChanges();
            }
          });
        }
      });
      this.userProfileCharacters = myData?.first_name?.charAt(0) + myData?.last_name?.charAt(0);
    }
    this.getNotifications();
    console.log(this.enableSymbol)
  }
  getNotifications() {
    this.studentService.getStudentNotifications().subscribe(res=>{
      console.log("test notifications", res)
      this.notificationsCount = res.iresult.length;
      if (res.iresult.length > 0) {
        this.enableSymbol = true;
        this.changeDetectorRef.detectChanges();
      }
      else {
        this.enableSymbol = false;
        this.changeDetectorRef.detectChanges();
      }
    });
    console.log(this.enableSymbol)
  }

  logoutClearFuturz(): any {
    this.storage.clear();
    this.router.navigateByUrl(ROUTER_CONSTANATS.STUDENT.LANDING_PAGE);
  }
  goTo(link): any {
    this.router.navigateByUrl(link);
  }
  cancelPopup(event) {
    this.showShare = false;
  }
}

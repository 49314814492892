<div class="feedbackformcontainer">
  <div class="feedbackform">
    <form [formGroup]="feedbackForms" (ngSubmit)="onSubmit()">
      <h1 class="formHeading">Send us your feedback!!</h1>

      <div class="doYouHave">Do you have a suggestion or found same bug ?<br>Let us know in the field below.</div>

      <div class="experience">How was your experience ?</div>
      <div>
        <div *ngFor="let feed of feedback; let i = index" class="pull-left">
          <img src="assets/smile.svg" alt="" *ngIf="!feed.checked" (click)="selectRating(i)"
            class="smileratingImages" />
          <img src="assets/yellowSmile.svg" alt="" (click)="selectRating(i)" *ngIf="feed.checked"
            class="smileratingImages" />
        </div>
      </div>

      <textarea class="form-control describeExprience" formControlName="description" rows="3"
        placeholder="Describe your experience here..."></textarea>

      <div class="radioboxes">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="feedbackType" name="feedbackType" value="bug" />
          <label class="form-check-label">Bug</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="feedbackType" name="feedbackType"
            value="suggestion" />
          <label class="form-check-label">Suggestion</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="feedbackType" name="feedbackType"
            value="others" />
          <label class="form-check-label">Others</label>
        </div>
      </div>

      <button class="btn feedbackBtn">Send Feedback</button>
      <button type="button" class="btn btn-text" (click)="cancel()">Cancel</button>
    </form>
  </div>
</div>
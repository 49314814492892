<div class="Notes">
  <div class="Notes__notesHeader">
      <div class="row no-gutters align-items-center" >
          <div class="col-6 col-md-6 col-lg-6"><h1 class="Notes__notesHeader__notesMainHeading">Notes </h1></div>
          <div class="col-6 col-md-6 col-lg-6 text-right" (click)="updateStatusPopup()"><img [src]="IMAGES.CLOSE_ICON" alt="" class="Notes__notesHeader__closeImage cursor"></div>
      </div>
  </div>
  <form autocomplete="off"  #formDirective="ngForm"  id="loginForm" [formGroup]="noteForm" (ngSubmit)="onSubmit()">
  <div class="Notes__takeNoteSection">
      <div class="Notes__takeNoteSection__takeNote"> 
         
    <input type="text" placeholder="Title" class="Notes__takeNoteSection__takeNote__title" maxlength="50" [ngClass]="{ 'is-invalid': submitted && noteForm.controls.title.errors }" formControlName="title"/>      
    </div>
      <div class="Notes__takeNoteSection__takeANote Notes__takeNoteSection__takeNote"> 
    <textarea placeholder="Take a note" class="Notes__takeNoteSection__takeNote__comment" maxlength='250' [ngClass]="{ 'is-invalid': submitted && noteForm.controls.details.errors }" formControlName="details" rows="5">

    </textarea>      
    </div>
  </div>

  <div class="Notes__elemetsDonecontainer">
      <div class="row no-gutters align-items-center">
          <div class="col-7 col-md-7">
              <!-- <a href="" class="Notes__elemetsDonecontainer__elementImages"><img src="./images/notification.svg" alt=""></a>
              <a href="" class="Notes__elemetsDonecontainer__elementImages"><img src="./images/share.svg" alt=""></a>
              <a href="" class="Notes__elemetsDonecontainer__elementImages"><img src="./images/Delete.svg" alt=""></a>
              <a href="" class="Notes__elemetsDonecontainer__elementImages"><img src="./images/check.svg" alt=""></a> -->
          </div>
          <div class="col-5 col-md-5 col-lg-5 text-right">
              <button class="btn Notes__elemetsDonecontainer__doneBtn"> Done</button>
          </div>
      </div>
  </div>
</form>
  <div class="Notes__notesSection Notes__notesSection__scroll">
      <div class="row no-gutters" *ngFor="let item of notesList">
          <div class="col-8 col-md-8 col-lg-8">
              <div class="Notes__notesSection__notesTaken">
                  <span *ngIf="!studentDetails?.careerId">{{item.note}}</span>
                  <span *ngIf="studentDetails?.careerId">{{item.title}}</span>
              </div>
          </div>
          <div class="col-4 col-md-4 text-right"> 
              <div class="Notes__notesSection__moreoptions">
                    <div (click)="updateStatus(item)" class="more_icon">
                        <img [src]="IMAGES.MORE_ICON" alt="">
                    </div>
                  
                  <div class="Notes__notesSection__moreoptions__optionsDiv text-left"  *ngIf="item.showOptions" >
                      <!-- <div class="Notes__notesSection__moreoptions__notesTaken Notes__notesSection__moreoptions__Options">Archive</div> -->
                      <div class="Notes__notesSection__moreoptions__notesTaken Notes__notesSection__moreoptions__Options delete" (click)="editNotes(item)" *ngIf="studentDetails?.careerId"><i class="fa fa-pencil-square-o" aria-hidden="true"></i><span>Edit</span> </div>
                      <div class="Notes__notesSection__moreoptions__notesTaken Notes__notesSection__moreoptions__Options delete" (click)="deleteNote(item.id)"><i class="fa fa-trash" aria-hidden="true"></i><span>Delete</span> </div>
                      <!-- <div class="Notes__notesSection__moreoptions__notesTaken Notes__notesSection__moreoptions__Options">Open in Keep <span class="Notes__notesSection__moreoptions__openImage align-middle"><img src="./images/openImage.svg" alt=""></span></div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="background">
  </div>
 
 </div>
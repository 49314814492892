import { Component, OnInit } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../../shared/imageConstants';
import { ROUTER_CONSTANATS } from '../../../shared/routerUrl.constants';
import { StorageService } from '../../../services/storage.service';
import { Router } from '@angular/router';
import { ShortlistedCareersService } from './shortlisted-careers.service';

@Component({
  selector: 'cf-shortlisted-careers',
  templateUrl: './shortlisted-careers.component.html',
  styleUrls: ['./shortlisted-careers.component.scss'],
})
export class ShortlistedCareersComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  shortListCareers: any;
  constructor(private shortList: ShortlistedCareersService, private router: Router, private storage: StorageService) {}
  ngOnInit(): void {
    const myData = this.storage.getItem('userData');
    const data = { student_id: myData.student_id };
    this.shortList.getShortListedCareers(data).subscribe((res: any) => {
      this.shortListCareers = res.shortListCareers;
    });
  }
}

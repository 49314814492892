import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cf-career-search',
  templateUrl: './career-search.component.html',
  styleUrls: ['./career-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerSearchComponent implements OnInit {
  careers = [];
  page = 1;
  pageSize = 50;
  collectionSize: any;
  // To handle search things.
  searchOptions: any = [];
  listOfCareersBackup = [];
  categorylistbackup = [];

  constructor() {}

  ngOnInit(): void {}
  onKeyup(evt: any): any {
    const searchTerm = evt.target.value.trim().toLowerCase();
    if (searchTerm.length) {
      this.searchOptions = [
        ...this.categorylistbackup.filter((cat) => cat.categoryName.toLowerCase().indexOf(searchTerm) !== -1),
        ...this.listOfCareersBackup.filter((cat) => cat.description.toLowerCase().indexOf(searchTerm) !== -1),
      ];
    } else {
      this.searchOptions = [];
    }
  }
  onSelectFilter(filter: any): any {
    this.careers = filter.description
      ? [...this.listOfCareersBackup.filter((cat) => cat.description === filter.description)]
      : [...this.listOfCareersBackup.filter((cat) => cat.categoryName === filter.categoryName)];
    this.collectionSize = this.careers.length;
    this.careers = this.careers
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    this.searchOptions = [];
  }
  getStyle(option): any {
    return { background: option.color || '#' + Math.floor(Math.random() * 16777215).toString(16) };
  }
}

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '@app/services/storage.service';
import { IMAGE_CONSTANTS } from '../../imageConstants';
import { StudentDashboardService } from '../../../modules/student/student-dashboard/student-dashboard.service';
import { ReportsKnowmoreService } from '../../../services/reports-knowmore.service';
import { SettingsService } from '@app/modules/admin/settings/settings.service';
import { API_CONSTANATS } from '@app/shared/api.constants';
import { LoginService } from '@app/modules/student/login/login.service';
@Component({
  selector: 'cf-reports-drop-down',
  templateUrl: './reports-drop-down.component.html',
  styleUrls: ['./reports-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsDropDownComponent implements OnInit {
  selectedStream = '';
  IMAGES: any = IMAGE_CONSTANTS;
  htmlContent: string;
  description: string;
  paymentdata: any;
  reportsKnowMore: any;
  reportsKnowMoreTitle: any;
  reportsKnowMoreBody: any;
  permission: any;
  getUpdatedReports = false;
  updatedReportsInfo: any;
  constructor(
    private router: Router,
    private storage: StorageService,
    private dashboard: StudentDashboardService,
    private modalService: NgbModal,
    private knowMore: ReportsKnowmoreService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private loginService: LoginService,
  ) {

  }
  dropDownList: any;
  ngOnInit(): void {
    const myData = this.storage.getItem('userData');
    const data = { student_id: myData.student_id };
    this.getStudentSetting();
    console.log(this.router.url);

    //*ngIf="item.link!=='/yourCounsellor' || !checkPermission('register')"

    this.knowMore.getReportsKnowmore(data).subscribe((res: any) => {
      if (res.message === 'success') {
        this.reportsKnowMore = res.result;
      }
    });
  }
  updateLink() {
    let reportexist = this.storage.getItem('userData').reportExists;
    console.log(this.storage.getItem('userData').reportExists);
    if (this.getUpdatedReports) {
      reportexist = this.updatedReportsInfo;
    }
    this.dropDownList.forEach((element) => {
      if (this.router.url === element.link) {
        this.selectedStream = element.text;
        this.changeDetectorRef.detectChanges();
      }
      for (let key in reportexist) {
        console.log(key);
        console.log(reportexist[key]);
        if (element.name === key && reportexist[key] === true) {
          element.isPurchased = true;
        } else if (element.text === 'My Shortlisted Careers' && key === 'Co1' && reportexist[key] === true) {
          element.isPurchased = true;
        }
      }
    });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  private getStudentSetting() {
    console.log('permission------>');
    this.settingsService.getData(API_CONSTANATS.STUDENT.FEATURE_ACCESS).subscribe((success) => {
      this.permission = success.featureAccess[0].permission_ids.split(',');
      this.dropDownList = [
        {
          link: '/yourStream',
          text: 'My Streams',
          buyNow: 'Buy',
          details: 'Know more',
          isPurchased: false,
          name: 'Co1',
        },
        {
          link: '/careerOptions',
          text: 'My Career Options',
          buyNow: 'Buy',
          details: 'Know more',
          isPurchased: false,
          name: 'Co1',
        },
        {
          link: '/yourStrengths',
          text: 'My Greatest Strengths',
          buyNow: 'Buy',
          details: 'Know more',
          isPurchased: false,
          name: 'Ygs',
        },
        {
          link: '/yourPersonality',
          text: 'My Personality',
          buyNow: 'Buy',
          details: 'Know more',
          isPurchased: false,
          name: 'Cd',
        },
        {
          link: '/yourTraitos',
          text: 'My Traits & Definitions',
          buyNow: 'Buy',
          details: 'Know more',
          isPurchased: false,
          name: 'Td',
        },
        {
          link: '/careersList',
          text: 'My Career Enjoyment',
          buyNow: '',
          details: 'Know more',
          isPurchased: false,
          name: 'Cc',
        },
        {
          link: '/yourCounsellor',
          text: 'Career Counselling',
          buyNow: '',
          details: '',
          isPurchased: true,
        },
        {
          link: '/courses',
          text: 'My Courses',
          buyNow: '',
          details: 'Know more',
          isPurchased: true,
        },
        { link: '/resources', text: 'My Resources', buyNow: '', details: '', isPurchased: true },
        {
          link: '/careerListShortlisted',
          text: 'My Shortlisted Careers',
          buyNow: '',
          details: '',
          isPurchased: false,
        },
        {
          link: '/coursesListShortListed',
          text: 'My Shortlisted Courses',
          buyNow: '',
          details: '',
          isPurchased: true,
        },
        { link: '/yourNotes', text: 'My Notes', buyNow: '', details: '', isPurchased: true },
        { link: '/dashboard', text: 'Dashboard', buyNow: '', details: '', isPurchased: true },
      ];
      this.updateLink();
    });
  }
  checkPermission(pageName) {
    console.log('permission', this.permission);
    return this.permission.some(function (el) {
      return el === pageName;
    });
  }

  paymentprocess(payment, reportname, reportdescription) {
    let userData = this.storage.getItem('userData');
    this.description = reportdescription;
    let data = {
      productinfo: reportname,
      studentId: userData.student_id,
    };
    this.dashboard.getHashForPayment(data).subscribe((res: any) => {
      this.paymentdata = JSON.parse(res);
      this.modalService.dismissAll();
      this.htmlContent = res;
      this.modalService.open(payment);
    });
  }
  openReadMore(content: any, key: any): any {
    this.reportsKnowMoreBody = '';
    this.reportsKnowMoreTitle = '';
    if (key === 'Col1') {
      this.reportsKnowMoreBody = this.reportsKnowMore?.[0]?.know_more;
      this.reportsKnowMoreTitle = this.reportsKnowMore?.[0]?.name;
    } else if (key === 'Cd') {
      this.reportsKnowMoreBody = this.reportsKnowMore?.[3]?.know_more;
      this.reportsKnowMoreTitle = this.reportsKnowMore?.[3]?.name;
    } else if (key === 'Ygs') {
      this.reportsKnowMoreBody = this.reportsKnowMore?.[4]?.know_more;
      this.reportsKnowMoreTitle = this.reportsKnowMore?.[4]?.name;
    } else if (key === 'Td') {
      this.reportsKnowMoreBody = this.reportsKnowMore?.[0]?.know_more;
      this.reportsKnowMoreTitle = this.reportsKnowMore?.[0]?.name;
    } else if (key === 'Cc') {
      this.reportsKnowMoreBody = this.reportsKnowMore?.[2]?.know_more;
      this.reportsKnowMoreTitle = this.reportsKnowMore?.[2]?.name;
    }
    this.modalService.open(content, { size: 'lg' });
  }
  getUpdatedReport() {
    if (this.storage.getItem('loginDetails')) {
      this.loginService.loginCheck(this.storage.getItem('loginDetails')).subscribe((res: any) => {
        this.updatedReportsInfo = res.result.reportExists;
        this.getUpdatedReports = true;
        this.updateLink();
      })
    }
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cf-reports-footer',
  templateUrl: './reports-footer.component.html',
  styleUrls: ['./reports-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

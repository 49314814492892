import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { StudentDashboardService } from '../student-dashboard/student-dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'cf-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent implements OnInit {
  htmlContent: string;
  paymentdata: any;
  constructor(private dashboard: StudentDashboardService ,
    private modalService: NgbModal,private storage: StorageService) { }

  ngOnInit(): void {
  }
  closeModal() { this.modalService.dismissAll(); }


  paymentprocess(payment,reportname)
  {
    let userData= this.storage.getItem('userData');
    let data={
      productinfo:reportname,
      studentId:userData.student_id

    }
    this.dashboard.getHashForPayment(data).subscribe((res: any) => {      
      this.paymentdata=JSON.parse(res);
      this.modalService.dismissAll();
      this.htmlContent=res;
      this.modalService.open(payment);     
    });
  }
}

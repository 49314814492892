import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { API_CONSTANATS } from '../../../shared/api.constants';

@Injectable({
  providedIn: 'root',
})
export class ShortlistedCareersService {
  apiBaseUrl = environment.serverUrl;
  constructor(private http: HttpClient) {}

  getShortListedCareers(data): Observable<any> {
    return of({
      shortListCareers: [
        {
          heading: 'Dentist',
          subText: 'Healthcare',
          qualification: 'Bachelors Degree',
          careerSutability: '88.90',
          progressBackground: 'bg-warning',
        },
        {
          heading: 'Dentist',
          subText: 'Healthcare',
          qualification: 'Bachelors Degree',
          careerSutability: '88.90',
          progressBackground: 'bg-success',
        },
        {
          heading: 'Dentist',
          subText: 'Healthcare',
          qualification: 'Bachelors Degree',
          careerSutability: '88.90',
          progressBackground: 'bg-danger',
        },
      ],
    });
  }
}

import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(private storageService: StorageService) { }

  getCurrentUserDetails() {
    return this.storageService.getItem('loggedInUserDetails');
  }

  getCurrentUserToken() {
    return this.storageService.getItem('token');
  }
  getCurrentUserPermission() {
    return this.storageService.getItem('permissionAccess');
  }
}

<router-outlet cfBlockCopyPaste *ngIf="(online$ | async)"></router-outlet>
<div *ngIf="!(online$ | async)">
  <div class="connectionIssue">
    
      <h3 class="errHeadingText">Clearfuturz say's</h3>
    <br><br>
    <b>Please check your internet connection, then try again</b>
  </div>

</div>

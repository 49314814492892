<div class="payment"><form action="https://test.payu.in/_payment" id="payment_form" method="Post">
    
	<input type="hidden" id="udf5" name="udf5" value="{{paymentdata.udf5}}" />    
	<input type="hidden" id="surl" name="surl" value="{{paymentdata.surl}}" />
	<input type="hidden" id="furl" name="furl" value="{{paymentdata.furl}}" />
  <input type="hidden" id="curl" name="curl" value="{{paymentdata.curl}}" />
  <input type="hidden" id="key" name="key" value="{{paymentdata.key}}" />  
  <div><span>Transaction ID :</span><span>{{paymentdata.txnid}}</span></div> 
  <div><span>Amount :</span><span>{{paymentdata.amount}}</span></div> 
  <input type="hidden" id="txnid" name="txnid" placeholder="Transaction ID" value="{{paymentdata.txnid}}" />
  <input type="hidden" id="amount" name="amount" placeholder="Amount" value="{{paymentdata.amount}}" />
  <input type="hidden" id="productinfo" name="productinfo" placeholder="Product Info" value="{{paymentdata.productinfo}}" />
  <input type="hidden" id="firstname" name="firstname" placeholder="First Name" value="{{paymentdata.firstname}}" />
	<input type="hidden" id="Lastname" name="Lastname" placeholder="Last Name" value="" />
	<input type="hidden" id="email" name="email" placeholder="Email ID" value="{{paymentdata.email}}" />
  <input type="hidden" id="phone" name="phone" placeholder="Mobile/Cell Number" value="{{paymentdata.phone}}" />
  <input type="hidden" id="address1" name="address1" placeholder="Address1" value="" />
  <input type="hidden" id="address2" name="address2" placeholder="Address2" value="" />
  <input type="hidden" id="city" name="city" placeholder="City" value="" />
  <input type="hidden" id="state" name="state" placeholder="State" value="" />
  <input type="hidden" id="country" name="country" placeholder="Country" value="" />
	<input type="hidden" id="Zipcode" name="Zipcode" placeholder="Zip Code" value="" />
	<input type="hidden" id="Pg" name="Pg" placeholder="PG" value="CC" />
	<input type="hidden" id="hash" name="hash" placeholder="Hash" value="{{paymentdata.hash}}" />
  <div id="alertinfo" class="dv"></div>
    
    <div><input type="submit" value="Confirm" class="button" />
      <input type="button" value="Cancel" class="button-cnl"  (click)="closeModal()"/></div>
	</form></div>
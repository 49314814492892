<footer class="mainFooter">
  <div class="row  no-gutters">
    <div class="col-12 col-md-12 mainFooter__mainContent">
      <img src="{{IMAGES.TWO_LINES}}" alt="TWO_LINES2" class="mainFooter__mainContent__footerBackImage">
      <div class="mainFooter__mainContent__footerLinks">
        <!-- <div class="row no-gutters ul">
          <div class="col-12 col-md-3 mx-auto">
            <ul class="list-unstyled ul-1">
              <li><a class="" routerLink="#">Home</a></li>
              <li><a class="" routerLink="#">Career Platform</a></li>
              <li><a class="" routerLink="#">Clear Futurz Values</a></li>
              <li><a class="" routerLink="#">Clear Futurz Team</a></li>
              <li><a class="" routerLink="#">Help</a></li>
            </ul>
          </div>
          <div class="col-12 col-md-3 mx-auto">
            <ul class="list-unstyled ul-2">
              <li><a class="" routerLink="#">Pricing</a></li>
              <li><a class="" routerLink="#">Career Stage</a></li>
              <li><a class="" routerLink="#">Job Opportunities</a></li>
              <li><a class="" (click)="showAboutSystem=!showAboutSystem">About Us</a></li>
              <li><a class="" routerLink="/termsAndConditions" target="_blank">Terms And Privacy</a></li>
            </ul>
          </div>
          <div class="col-12 col-md-3 mx-auto">
            <ul class="list-unstyled ul-3">
              <li><a class="" routerLink="#">Blogs</a></li>
              <li><a class="" routerLink="#">News Room</a></li>
              <li><a class="" routerLink="#">FAQ's</a></li>
              <li><a class="" routerLink="#">Support</a></li>
            </ul>
          </div>

        </div> -->
        <app-aboutthissystem *ngIf="showAboutSystem" (cancelOutput)="cancelPopup($event)"></app-aboutthissystem>
      </div>

      <div class="row container no-gutters ">
        <div class="col-12 col-md-3 mx-auto">
          <img src="{{IMAGES.CALL}}" alt="CALL2" class="call">
        </div>
        <div class="col-12 col-md-4 ml-auto  mainFooter__mainContent__contactUs  ">
          <h2>Contact Us Now</h2>
          <h2 class="phone">+91 9392471177
          </h2>
          <!-- <span class="keyboardicon">@</span>
          <div class="mainFooter__mainContent__contactUs__contactForm width">
            <form>
              <input type="text" class="form-control" id="fullName" placeholder="Full Name">
              <input type="text" class="form-control" id="company" placeholder="Company">
              <input type="email" class="form-control" id="email" placeholder="Email">
              <input type="text" class="form-control" id="phone" placeholder="Phone No.">
              <select class="form-control" id="enquiryReason">
                <option>Reason for Enquiry</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <textarea class="form-control" id="comments" rows="4" placeholder="Comments"></textarea>
              <div type="submit" class="btn btn-primary mt-3">Submit</div>
            </form>
          </div> -->
        </div>
        <div class="col-12 col-md-4 mx-auto">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-9"><a class="terms" [routerLink]="['/termsAndConditions']" target="_blank">Terms And Privacy</a></div>
          </div>
          <div class="row ">
            <div class="col-3 mainFooter__mainContent__contactUs__contactPic img-fluid">
              <!-- <img src="{{IMAGES.CALL}}" alt="CALL2"> -->
            </div>
            <div class="col-9  mainFooter__mainContent__contactUs__contactInformation">
              <!-- <h2>+91 9392471177
              </h2> -->
              <h3 class="education">For Educational & Institutional Enquiry</h3>
              <p>Mail Us- support@clearfuturz.com</p>

              <h3 class="business">For Business Enquiry</h3>
              <p>Mail Us- support@clearfuturz.com</p>

              <!-- <h4>Follow Us On</h4>
              <div class="mainFooter__mainContent__contactUs__contactInformation__followUs">
                <a routerLink=""><img src="{{IMAGES.FACEBOOK}}" alt="FACEBOOK"></a>
                <a routerLink=""><img src="{{IMAGES.INSTAGRAM}}" alt="INSTAGRAM"></a>
                <a routerLink=""><img src="{{IMAGES.GOOGLE}}" alt="GOOGLE"></a>
              </div> -->
            </div>
          </div>
        </div>

      </div>

      <div class="mainFooter__copyWrite">
        <p>&#169; 2019-20 ClearFuturZ all right reserved.</p>
      </div>
    </div>
    <!-- <div class=" col-12 col-md-3 ">
      <div class="mainFooter__sideTopContent">
        <div class="mainFooter__sideTopContent__takeTest">
          <h2>Take Free Test Now</h2>
          <div class="mainFooter__sideTopContent__takeTest__freeTest take-test"> Take free test</div>
          

          <div class="mainFooter__sideTopContent__takeTest__img">
            <img src="../../../../assets/images/clear_futurz_newimages/lb-testnow.png" alt="TESTNOW" height="350" width="" id="demo">
          </div>
        </div>

      </div>
      <div class="mainFooter__sideBottomContent">
        <div class="mainFooter__sideBottomContent__takeDemo">
          <h2>Try our<br> Interactive Demo</h2>
          <div class="btn shadow"> <img src="{{IMAGES.PLAY_TWO}}" alt="PLAY_TWO"></div>
          <div class="mainFooter__sideBottomContent__takeDemo__image2">
            <img src="{{IMAGES.GET_STARTED}}" alt="GET_STARTED" height="230px" width="300" id="demo">
          </div>
        </div>

      </div>
    </div> -->
  </div>
</footer>
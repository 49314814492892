<div class="headerSearch">
  <div class="searchContainer">
    <img src="../../../../assets/icons/searchIcon.png" class="searchIcon" />
    <input type="text" class="form-control search"
      placeholder="{{ courcesTab ? 'Search by course name' : 'Search by career name' }}" (keyup)="onKeyup($event)"
      [(ngModel)]="searchValue" #searchData/>
      <div class="close_icon" *ngIf="eventVal.length > 0" (click)="cancelSearch()"><img src="assets/note_cancel.svg"/></div>
    <img class="cancelsearch" src="assets/note_cancel.svg" (click)="searchData.value = ''; onKeyup($event)"
      *ngIf="crossMark" />
    <div *ngIf="searchOptions.length > 0 && searchOptionClicked == false" class="search-options" id="search-overflow"
      (clickOutside)="close($event)">
      <div *ngFor="let option of searchOptions;let i=index" (click)="onSelectFilter(option)" class="searchOption-opt"
        [id]="'headerSearchOp' + i" [ngClass]="{ 'focus-d': i == selectedIndex }">
        <div class="row align-items-center m-0">
          <div class="col-2">
            <img *ngIf="option.image" src="{{ !courcesTab ?apiCmsUrl :''}}{{ option.image}}" class="optionImg" />
            <span class="icon" *ngIf="!option.image" [style]="getStyle(option)">{{
              (option.description || option.categoryName || option.course_name).substring(0, 1)
              }}</span>
          </div>
          <div class="col-10">
            <!-- <span class="icon"
            [style]="getStyle(option)">
            <img src="http://23.21.197.188:1337{{option.image}}"
            alt=""></span> -->
            <span>{{ option.description || option.categoryName || option.course_name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
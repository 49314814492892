import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { IMAGE_CONSTANTS } from "./../../imageConstants";
import { StudentService } from "./../../../modules/admin/student/student.service";
import { API_CONSTANATS } from './../../api.constants';
import { StorageService } from "./../../../services/storage.service";

import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'cf-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @ViewChild('formDirective') private formDirective: NgForm;
  @Output() public onClose = new EventEmitter<boolean>()
  @Input() studentDetails: any;

  public IMAGES = IMAGE_CONSTANTS;
  public showOptions: any;
  public notesList: any;
  public noteForm: FormGroup;
  public submitted: boolean;
  public submitButton: boolean;
  public editId: string;
  public myData: any;
  constructor(private studentService: StudentService, private storageService: StorageService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    this.init()
    this.setFormFields()
  }
  get noteRegistration(): any {
    return this.noteForm.controls;
  }
  public setFormFields(): any {
    this.noteForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      details: ['', [Validators.required]],
    });
  }
  public updateStatus(itemDetails) {
    if(itemDetails.showOptions) {
      itemDetails.showOptions = false
    } else {
      itemDetails.showOptions = true
    }
    
  }

  public onSubmit(): any {
    this.submitButton = true;
   // console.log('editId', this.editId)
    if (this.noteForm.invalid) {
      this.submitButton = false;
      this.submitted = true;
      return;
    }
    this.addNotes();
  }

  public addNotes() {
    let params, url
    console.log('-->', this.editId)
    if(this.editId && !this.studentDetails?.careerId) {
      params = {
        note: this.noteRegistration.details.value,
        Id: this.editId,
        title: this.noteRegistration.title.value,
      };
      url = API_CONSTANATS.ADMIN.CANDIDATE_NOTE_EDIT
    }
    else if (this.studentDetails?.careerId && !this.editId) {
      params = {
        "studentId": this.myData.student_id,
        "careerId": this.studentDetails?.careerId,
        "title": this.noteRegistration.title.value,
        "notes": this.noteRegistration.details.value
      }
      url = API_CONSTANATS.ADMIN.SAVE_STUDENT_CAREER_NOTES;
    }else if (this.studentDetails?.careerId && this.editId) {
      params = {
        title: this.noteForm.value.title,
        notes: this.noteForm.value.details,
        Id: this.editId
      }
      url = API_CONSTANATS.ADMIN.CAREER_NOTE_UPDATE;
    }else {
      params = {
        note: this.noteRegistration.details.value,
        studentId: this.myData.student_id,
        title: this.noteRegistration.title.value,
      };
      url = API_CONSTANATS.ADMIN.CANDIDATE_NOTE_ADD
    }
   
    //this.spinner.show('citySpinner');
    this.studentService.getPostData(url, params).subscribe((sucess) => {
      this.init();
      // this.toastr.success('Success!', 'Notes added successfully');
      if (this.studentDetails?.careerId && !this.editId) {
        this.toastr.success('Success!', 'Notes added successfully');
      }
      if(this.editId) { this.editId= undefined
        if (this.studentDetails?.careerId) {
          this.toastr.success('Success!', 'Notes updated successfully');
        }
      }
      this.formDirective.resetForm();
    });
  }

  updateStatusPopup() {
    this.onClose.emit(false)
  }

  private init() {
    this.myData = this.storageService.getItem('userData');
    if (this.studentDetails?.careerId) {
      this.studentService
      .getPostData(API_CONSTANATS.ADMIN.GET_STUDENT_CAREER_NOTES, { studentId: this.myData.student_id, careerId: this.studentDetails?.careerId })
      .subscribe((sucess) => {
        this.notesList = sucess.result;
      });
    } else {
    this.studentService
      .getPostData(API_CONSTANATS.ADMIN.CANDIDATE_NOTE_LIST, { studentId: this.myData.student_id })
      .subscribe((sucess) => {
      //  this.spinner.hide('citySpinner');
        console.log('sucess', sucess);
        this.notesList = sucess.result;
      });
    }
  }
  public deleteNote(id) {
    this.spinner.show('citySpinner');
    let api = API_CONSTANATS.ADMIN.CANDIDATE_NOTE_DELETE;
    if (this.studentDetails?.careerId) {
      api = API_CONSTANATS.ADMIN.CAREER_NOTE_DELETE
    }
    this.studentService.getPostData(api, { Id: id }).subscribe((sucess) => {
      this.init();
      this.spinner.hide('citySpinner');
      this.toastr.success('Success!', 'Notes deleted successfully');
    });
  }
  public editNotes(notesDetails) {
    this.notesList.filter(notes => {
      if (notes.id  === notesDetails.id) {
        notes.showOptions = false;
      }
    });
    this.spinner.show("citySpinner")
    this.noteForm.controls['title'].setValue(notesDetails.title);
    this.noteForm.controls['details'].setValue(notesDetails.note);
    this.editId = notesDetails.id;
  }
}
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CourcesListComponent } from './cources-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPagination, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { SharedModule } from '../../../../shared/shared.module';
@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    // NgbPaginationModule,
  ],
  exports: [
  ]
  // schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CourseListModule { }

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../imageConstants';
import { ResourcesService } from '../../../services/resources.service';
import { StorageService } from '../../../services/storage.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cf-reports-books',
  templateUrl: './reports-books.component.html',
  styleUrls: ['./reports-books.component.scss']
})
export class ReportsBooksComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  rebooksData: any;
  bresults: any;
  reportID: string;
  // booksData: any = [
  //   [
  //     { title: 'Title 1', subTitle: 'Sub title for book 1', price: '506' },
  //     { title: 'Title 2', subTitle: 'Sub title for book 2', price: '200' },
  //   ],
  //   [
  //     { title: 'Title 3', subTitle: 'Sub title for book 3', price: '900' },
  //     { title: 'Title 4', subTitle: 'Sub title for book 4', price: '1000' },
  //   ],
  //   [
  //     { title: 'Title 5', subTitle: 'Sub title for book 1', price: '506' },
  //     { title: 'Title 6', subTitle: 'Sub title for book 2', price: '200' },
  //   ],
  // ];

  constructor( private urlParams: ActivatedRoute,private resources: ResourcesService, private storage: StorageService, public sanitizer: DomSanitizer) {
    
  }

  ngOnInit(): void { 
    this.urlParams.queryParams.subscribe((params) => {
      this.reportID = params.id;
      console.log("this.reportID",this.reportID)
    });
    const student = this.storage.getItem('userData');
    const data = { student_id: student.id,careerDetailID : this.reportID };
    if(this.reportID)
    {
      this.resources.getCareerBooks(data).subscribe((booksList: any) => {
        this.rebooksData = booksList.books;
        console.log('booksData', this.rebooksData);
        this.rebooksData.forEach((element) => {
          element.Books=[];
          element.Books.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(element.book_url);
        });
        this.bresults = [];
        const chunk_size = 2;
        while (this.rebooksData.length > 0) {
          this.bresults.push(this.rebooksData.splice(0, chunk_size));
        }
      });
    }else{
      this.resources.getBooks(data).subscribe((booksList: any) => {
        this.rebooksData = booksList;
        console.log('booksData', this.rebooksData);
        this.rebooksData.forEach((element) => {
          element.Books.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(element.Books.book_url);
        });
        this.bresults = [];
        const chunk_size = 2;
        while (this.rebooksData.length > 0) {
          this.bresults.push(this.rebooksData.splice(0, chunk_size));
        }
      });
    }
   
  }

  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "autoplay": true};
  
  addSlide() {
    this.bresults.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.bresults.length = this.bresults.length - 1;
  }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { API_CONSTANATS } from '../shared/api.constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CreateShortlistService {
  apiBaseUrl = environment.serverUrl;

  constructor(private http: HttpClient) {}
  addCareerToShortlisted(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_SHORT_LIST_CAREER}`, data);
  }
  saveCareerProspect(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.SAVE_CAREER_PROSPECT}`, data);
  }
  updateCareerIndex(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.UPDATE_CAREER_INDEX}`, data);
  }
}

import { Component, OnInit,Output,EventEmitter, ChangeDetectorRef} from '@angular/core';
import { CurrentUserService } from '@app/services/currentUser.service';
import { CurrentUser } from '@app/core/models/currentUser';
import { Role } from '@app/core/models/role';
import { SettingsService } from './../settings.service';

@Component({
  selector: 'app-aboutthissystem',
  templateUrl: './aboutthissystem.component.html',
  styleUrls: ['./aboutthissystem.component.scss']
})
export class AboutthissystemComponent implements OnInit {
  @Output() cancelOutput:EventEmitter<string>= new EventEmitter();
  aboutData:any=[];
  public currentUser: CurrentUser;
  public role = Role;

  constructor(
    private https: SettingsService,
    // private storage: StorageService,
    private currentUserService: CurrentUserService,
    private changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.currentUser = this.currentUserService.getCurrentUserDetails();
    console.log(this.currentUser, "user")
    this.getAboutSystem()
  }

  getAboutSystem(){
  this.https.getAboutSystem().subscribe(res=>{
    console.log('>>>>',res);
    this.aboutData=res;
    this.changeDetectorRef.detectChanges();
    console.log(this.aboutData);
  });
  }
  cancel(){
    this.cancelOutput.emit('cancel');
  }
}

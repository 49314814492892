import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../imageConstants';
@Component({
  selector: 'cf-normal-logo',
  templateUrl: './normal-logo.component.html',
  styleUrls: ['./normal-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NormalLogoComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  constructor() { }
  ngOnInit(): void {
  }
}

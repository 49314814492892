<div class="careerOptions">
  <div class="row no-gutters">
    <div class="col-9 col-md-9">
      <div class="leftSection">
        <div class="careerOptions__navigation">
          <div class="row">
            <div class="col-md-1 cusotmLogo">
              <cf-color-logo></cf-color-logo>
            </div>
            <div class="col-md-3">
              <cf-reports-drop-down></cf-reports-drop-down>
            </div>
            <div class="col-md-6">
              <div class="headerSearch">
                <div class="searchContainer">
                  <img src="../../../../assets/icons/searchIcon.png" class="searchIcon" />
                  <input
                    type="text"
                    placeholder="Search by career name"
                    class="form-control search"
                    (keyup)="onKeyup($event)"
                    [(ngModel)]="searchValue"
                    #searchData
                  />
                  <img
                    class="cancelsearch"
                    src="assets/note_cancel.svg"
                    (click)="searchData.value = ''; onKeyup($event)"
                    *ngIf="crossMark"
                  />

                  <div *ngIf="searchOptions.length > 0" class="search-options" id="search-overflow">
                    <div
                      *ngFor="let option of searchOptions; let i = index"
                      (click)="onSelectFilter(option)"
                      class="searchOption-opt"
                      [id]="'headerSearchOp' + i"
                      [ngClass]="{ 'focus-d': i == selectedIndex }"
                    >
                      <div class="row align-items-center m-0">
                        <div class="col-2">
                          <img *ngIf="option.image" src="{{ apiCmsUrl }}{{ option.image }}" class="optionImg" />
                          <span class="icon" *ngIf="!option.image" [style]="getStyle(option)">{{
                            (option.description || option.categoryName).substring(0, 1)
                          }}</span>
                        </div>
                        <!-- <div class="col-10">
                          <span>{{ option.description || option.categoryName }}</span>
                          <span *ngIf="option.score > 0" class="score">{{option.score | number:'1.1-1'}}%</span>
                        </div> -->
                        <div class="col-8">
                          <span>{{ option.description || option.categoryName }}</span>
                        </div>
                        <div class="col-2">
                          <span class="score">{{ option.score | number: '1.1-1' }}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <cf-reports-profile></cf-reports-profile>
            </div>
          </div>
        </div>
        <table class="table table-borderless">
          <thead>
            <tr>
              <th class="career" colspan="3"><span class="careerOptionTableHeader"> Career & Category </span></th>
              <th class="qualification"><span class="careerOptionTableHeader"> Min. Qualification</span></th>
              <th class="suitability" colspan="2">
                <span class="careerOptionTableHeader"
                  ><img src="{{ IMAGES.ARROW_DOWN }}" class="downArrow" (click)="sortByScore()" />Your Career
                  Satisfaction / Suitability %</span
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of careers | slice: (page - 1) * pageSize:page * pageSize; let i = index">
              <tr class="tableData">
                <td class="tableData__careerData textMiddle">
                  <span class="tableData__careerData__star">
                    <img
                      src="{{ item.shortlisted === 'true' ? IMAGES.HEART_ICON : IMAGES.FAV_ICON }}"
                      alt=""
                      (click)="shortListingCareer(item.ha_id, item.shortlisted)"
                    />
                  </span>
                </td>
                <td class="tableData__careerDataProfile textMiddle">
                  <span *ngIf="item.image" style="width: 100%" class="tableData__careerDataProfile__careerImage"
                    ><img src="{{ apiCmsUrl }}{{ item.image }}" alt=""
                  /></span>
                  <span *ngIf="item.image == null" class="tableData__careerDataProfile__careerImage"
                    ><img src="{{ IMAGES.DENTIST_IMAGE }}" alt=""
                  /></span>
                </td>
                <td
                  class="tableData__careerDataMore textMiddle"
                  (click)="goToCareerDetails(item.detailsid, item.score)"
                >
                  <div class="tableData__careerDataMore__careerTitles">
                    <p class="tableData__careerDataMore__careerTitles__title">{{ item.description }}</p>
                    <!-- <p class="tableData__careerDataMore__careerTitles__subTitle">{{item.categoryName}}{{item.categoryName.length}}
                    <span class="tableData__careerDataMore__careerTitles__subTitle__brightCareer"></span><span class="bright">Bright Career</span></p> -->
                    <p *ngIf="item.categoryName.length <= 33" class="tableData__careerDataMore__careerTitles__subTitle">
                      {{ item.categoryName }}
                      <span *ngIf="item.IsBrightCareer !== 'false'"
                        ><span class="tableData__careerDataMore__careerTitles__subTitle__brightCareer"></span
                        ><span class="bright">Bright Career</span></span
                      >
                    </p>
                    <p *ngIf="item.categoryName.length >= 34" class="tableData__careerDataMore__careerTitles__subTitle">
                      {{ item.categoryName }}<br />
                      <span *ngIf="item.IsBrightCareer !== 'false'"
                        ><span class="tableData__careerDataMore__careerTitles__subTitle__brightCareer"></span
                        ><span class="bright">Bright Career</span></span
                      >
                    </p>
                  </div>
                </td>
                <td
                  class="tableData__qualificationData textMiddle"
                  (click)="goToCareerDetails(item.detailsid, item.score)"
                >
                  <p class="tableData__qualificationData__reqQualificationData">
                    <span *ngIf="item.qualificationName === 'Vocational school / 6-18 months of training'"
                      >Vocational school / <br />6-18 months of training</span
                    ><span *ngIf="item.qualificationName !== 'Vocational school / 6-18 months of training'">
                      <span *ngIf="item.qualificationName === '2 years of college / 18-36 months of training'"
                        >2 years of college /<br />
                        18-36 months of training</span
                      ><span *ngIf="item.qualificationName !== '2 years of college / 18-36 months of training'">{{
                        item.qualificationName
                      }}</span></span
                    >
                  </p>
                </td>
                <td class="suitabilityProgress textMiddle" (click)="goToCareerDetails(item.detailsid, item.score)">
                  <div class="progress">
                    <div
                      *ngIf="item.score < grades.gradeC.maximum"
                      class="progress-bar barIndicator suitabilityProgress__redBar"
                      [ngStyle]="{ width: item.score + '%' }"
                    ></div>
                    <div
                      *ngIf="item.score > grades.gradeB.minimum && item.score < grades.gradeB.maximum"
                      class="progress-bar barIndicator suitabilityProgress__yellowBar"
                      [ngStyle]="{ width: item.score + '%' }"
                    ></div>
                    <div
                      *ngIf="item.score > grades.gradeA.minimum"
                      class="progress-bar barIndicator suitabilityProgress__greenBar"
                      [ngStyle]="{ width: item.score + '%' }"
                    ></div>
                  </div>
                </td>
                <td
                  class="tableData__suitabilityScore textMiddle"
                  (click)="goToCareerDetails(item.detailsid, item.score)"
                >
                  <span class="tableData__suitabilityScore__careerScore">{{ item.score | number: '1.1-1' }}</span>
                </td>
              </tr>
              <tr *ngIf="i === 4">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div style="width: 100%" class="text-center">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                      crossorigin="anonymous"></script>
                  <!-- CareerOptions6*1 -->
                  <ins class="adsbygoogle"
                      style="display:inline-block;width:910px;height:56px"
                      data-ad-client="ca-pub-4835826878013634"
                      data-ad-slot="3732964160"></ins>
                  <script>
                      (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 9">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                        crossorigin="anonymous"></script>
                    <!-- CareerOptions6*2 -->
                    <ins class="adsbygoogle"
                        style="display:inline-block;width:910px;height:56px"
                        data-ad-client="ca-pub-4835826878013634"
                        data-ad-slot="8970908007"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 14">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                    crossorigin="anonymous"></script>
               <!-- CareerOptions6*3 -->
               <ins class="adsbygoogle"
                    style="display:inline-block;width:910px;height:56px"
                    data-ad-client="ca-pub-4835826878013634"
                    data-ad-slot="5020989518"></ins>
               <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
               </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 19">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                        crossorigin="anonymous"></script>
                    <!-- CareerOptions6*4 -->
                    <ins class="adsbygoogle"
                        style="display:inline-block;width:910px;height:56px"
                        data-ad-client="ca-pub-4835826878013634"
                        data-ad-slot="3855219259"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 24">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                            crossorigin="anonymous"></script>
                        <!-- CareerOptions6*5 -->
                        <ins class="adsbygoogle"
                            style="display:inline-block;width:910px;height:56px"
                            data-ad-client="ca-pub-4835826878013634"
                            data-ad-slot="3676118250"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({});
                        </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 29">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                        crossorigin="anonymous"></script>
                    <!-- Careeroptions6*6 -->
                    <ins class="adsbygoogle"
                        style="display:inline-block;width:910px;height:56px"
                        data-ad-client="ca-pub-4835826878013634"
                        data-ad-slot="4976729233"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 34">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                      crossorigin="anonymous"></script>
                  <!-- CareerOptions6*7 -->
                  <ins class="adsbygoogle"
                      style="display:inline-block;width:910px;height:56px"
                      data-ad-client="ca-pub-4835826878013634"
                      data-ad-slot="1049954914"></ins>
                  <script>
                      (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 39">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                      crossorigin="anonymous"></script>
                  <!-- CareerOptions6*8 -->
                  <ins class="adsbygoogle"
                      style="display:inline-block;width:910px;height:56px"
                      data-ad-client="ca-pub-4835826878013634"
                      data-ad-slot="6693163836"></ins>
                  <script>
                      (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 44">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                      crossorigin="anonymous"></script>
                          <!-- CareerOptions6*9 -->
                          <ins class="adsbygoogle"
                              style="display:inline-block;width:910px;height:56px"
                              data-ad-client="ca-pub-4835826878013634"
                              data-ad-slot="3555573021"></ins>
                          <script>
                              (adsbygoogle = window.adsbygoogle || []).push({});
                          </script>
                  </div>
                </td>
              </tr>
              <tr *ngIf="i === 49 || i === careers.length - 1">
                <td colspan="6" class="text-center" style="width: 100%">
                  <div class="text-center" style="width: 100%">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                      crossorigin="anonymous"></script>
                  <!-- CareerOptions6*10 -->
                  <ins class="adsbygoogle"
                      style="display:inline-block;width:910px;height:56px"
                      data-ad-client="ca-pub-4835826878013634"
                      data-ad-slot="2242491350"></ins>
                  <script>
                      (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>

                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div class="d-flex justify-content-between p-2 float-right">
          <ngb-pagination
            [collectionSize]="careers?.length"
            [(page)]="page"
            [pageSize]="pageSize"
            (pageChange)="onPageChange()"
          ></ngb-pagination>
        </div>
      </div>
    </div>
    <div class="col-3 col-md-3">
      <div class="rightSection">
        <div class="">
          <div class="rightSection__topNavigation">
            <div class="row no-gutters">
              <div [ngClass]="filterSection ? 'col rightSection__topNavigation__bottomImage' : 'col'">
                <img
                  class="rightSection__topNavigation__rightSectionImages"
                  src="{{ IMAGES.FILTER_ICON_BLUE }}"
                  (click)="filter()"
                  [ngStyle]="filterSection ? { float: '' } : { float: 'left' }"
                />
                <span
                  [ngClass]="
                    filterSection
                      ? 'rightSection__topNavigation__visibleSection'
                      : 'rightSection__topNavigation__notVisibleSection'
                  "
                >
                  Filter</span
                >
              </div>
              <div [ngClass]="notesSection ? 'col-4 rightSection__topNavigation__bottomImage' : 'col'">
                <img
                  [ngStyle]="filterSection ? { float: 'right' } : notesSection ? { float: '' } : { float: 'left' }"
                  class="rightSection__topNavigation__rightSectionImages"
                  src="{{ IMAGES.BOOK_ICON }}"
                  (click)="notes()"
                />
                <span
                  [ngClass]="
                    notesSection
                      ? 'rightSection__topNavigation__visibleSection'
                      : 'rightSection__topNavigation__notVisibleSection'
                  "
                >
                  Notes
                </span>
              </div>
              <!-- <div
                [ngClass]="shareSection? 'col-4 rightSection__topNavigation__bottomImage': 'col'">
                <img
                  [ngStyle]="filterSection? ({'float':'right'}) :notesSection? ({'float':'right'}) : shareSection? ({'float':''}) : ({'float':'left'}) "
                  class="rightSection__topNavigation__rightSectionImages " src="{{IMAGES.SHARE_ICON_GREEN}}"
                  (click)="share()" />
                <span
                  [ngClass]="shareSection?'rightSection__topNavigation__visibleSection':'rightSection__topNavigation__notVisibleSection'">
                  Share </span>
              </div> -->
              <div [ngClass]="feedbackSection ? 'col-5 rightSection__topNavigation__bottomImage' : 'col'">
                <img
                  [ngStyle]="shareSection ? { float: 'right' } : feedbackSection ? { float: '' } : { float: 'right' }"
                  class="rightSection__topNavigation__rightSectionImages"
                  src="{{ IMAGES.FEED_BACK }}"
                  (click)="showShare = !showShare"
                />
                <span
                  [ngClass]="
                    feedbackSection
                      ? 'rightSection__topNavigation__visibleSection'
                      : 'rightSection__topNavigation__notVisibleSection'
                  "
                >
                  Feedback
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="filterSection">
            <div class="searchCareerInput">
              <div class="searchContainer">
                <input
                  type="text"
                  class="form-control search"
                  [(ngModel)]="searchFilter"
                  placeholder="Search Category"
                />
                <!-- <input type="text" class="form-control search" [(ngModel)]="selectedCareer" (keyup)="onKeyupCareer($event)" />  -->
                <img
                  class="cancelIcon"
                  *ngIf="selectedCareer"
                  src="{{ IMAGES.CANCEL_ICON }}"
                  src=""
                  (click)="clearSearch()"
                />
                <img class="searchIcon" src="../../../../assets/icons/searchIcon.png" />
                <!--<div *ngIf="searchCareerOptions.length > 0" class="search-options">
                <div *ngFor="let option of searchCareerOptions" (click)="onCareerSelectFilter(option)">
                  <span class="icon"
                    [style]="getStyle(option)">{{(option.description || option.categoryName).substring(0,1)}}</span>
                  <span>{{option.description || option.categoryName}}</span>
                </div>
              </div> -->
              </div>
            </div>

            <div class="qualification">
              <div class="qualificationText">Edu. Qualification (Minimum)</div>
              <div class="row no-gutters">
                <div class="col-12 col-md-12">
                  <div ngbDropdown class="d-inline-block careerMenu">
                    <div id="qualifications" ngbDropdownToggle class="careerMenuSub form-control">
                      {{ qualificationsListString }}
                    </div>
                    <div ngbDropdownMenu aria-labelledby="qualifications" class="educationList">
                      <span ngbDropdownItem *ngFor="let item of qualificationLineNew">
                        <input
                          type="checkbox"
                          [(ngModel)]="item.IsDefaultSelected"
                          (click)="qualificationBasedSearchNew()"
                        />
                        <span>{{ item.qualificationName }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="searchList">
              <div class="form-group">
                <div class="optionsMain">
                  <ng-container *ngFor="let item of topCareersList">
                    <div class="subText">
                      <button
                        [ngClass]="item.isSelected ? 'topList btn' : 'btn'"
                        (click)="topCareersListSearch(item.id)"
                      >
                        {{ item.textName }}
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="checkBoxList">
              <div
                class="form-check"
                *ngFor="let eachCategory of categorylistbackupNew | appFilter: searchFilter; index as i"
              >
                <!-- <div class="form-check" *ngFor="let eachCategory of categorylistbackupNew;index as i"> -->
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    value="{{ eachCategory.id }}"
                    [id]="'customCheck' + (i + 1)"
                    [(ngModel)]="eachCategory.IsDefaultSelected"
                    (click)="categoryBasedSearch($event)"
                  />
                  <label class="custom-control-label" htmlFor="{{ 'customCheck' + (i + 1) }}">{{
                    eachCategory.categoryName
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="notesSection">
            <div class="typeNote">
              <div class="row align-items-center no-gutters">
                <div class="col-10"><span class="pl-3">Type to take a note</span></div>
                <!-- <div class="col-2"><img [src]="IMAGES.CANCEL_ICON" alt="" class="pull-right pr-2" /></div> -->
              </div>
            </div>

            <form
              autocomplete="off"
              #formDirective="ngForm"
              id="loginForm"
              [formGroup]="noteForm"
              (ngSubmit)="onSubmit()"
            >
              <div class="leftTitleSection">
                <!-- <div class="leftTitleSection__leftTitle">Title</div>
              <div class="leftTitleSection__startTyping">Start typing here to take note...</div> -->
                <span class="form-group">
                  <input
                    type="text"
                    maxlength="50"
                    [ngClass]="{ 'is-invalid': submitted && noteForm.controls.title.errors }"
                    formControlName="title"
                    class="form-control leftTitleSection__leftTitle"
                    placeholder="Title"
                  />
                </span>
                <textarea
                  maxlength="250"
                  class="form-control leftTitleSection__startTyping"
                  id="exampleFormControlTextarea1"
                  [ngClass]="{ 'is-invalid': submitted && noteForm.controls.details.errors }"
                  formControlName="details"
                  rows="5"
                  placeholder="Start typing here to take note..."
                ></textarea>
                <div class="row align-items-center no-gutters">
                  <div class="col-8 col-md-10">
                    <!-- <div class="row align-items-center no-gutters">
                    <div class="col-2 col-md-2 text-center">
                      <img [src]="IMAGE.BELL_ICON" alt="" />
                    </div>
                    <div class="col-2 col-md-2 text-center">
                      <img [src]="IMAGE.SHARE_ICON" alt="" />
                    </div>
                    <div class="col-2 col-md-2 text-center">
                      <img [src]="IMAGE.DELETE_ICON" alt="" />
                    </div>
                    <div class="col-2 col-md-2 text-center">
                      <img [src]="IMAGE.CHECK_ICON" alt="" />
                    </div>
                  </div> -->
                  </div>
                  <div class="col-4 col-md-2" text-center>
                    <button class="done">Done</button>
                  </div>
                </div>
              </div>
            </form>

            <div class="optionsDiv optionsDiv__scroll">
              <div class="notesListView" *ngFor="let item of notesList">
                <div class="row no-gutters">
                  <div class="col-8 col-md-8">
                    <div class="optionsDiv__optionsText">
                      {{ item.title }}
                    </div>
                  </div>
                  <div class="col-4 col-md-4 text-right optionsDiv__OptionsImageDiv" ngbDropdown>
                    <img
                      [src]="IMAGES.MORE_ICON"
                      alt=""
                      class="optionsDiv__OptionsImageDiv__moreOptionsImage"
                      id="dropdownBasic{{ item.id }}"
                      ngbDropdownToggle
                    />
                    <div
                      class="optionsDiv__OptionsImageDiv__moreOptionsDiv text-left"
                      ngbDropdownMenu
                      aria-labelledby="dropdownBasic{{ item.id }}"
                    >
                      <div class="optionsDiv__OptionsImageDiv__moreOptionsDiv__moreOptions" (click)="editNotes(item)">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                      </div>
                      <div
                        class="optionsDiv__OptionsImageDiv__moreOptionsDiv__moreOptions"
                        (click)="deleteNote(item.id)"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i> Delete
                      </div>
                      <!-- <div class="optionsDiv__OptionsImageDiv__moreOptionsDiv__moreOptions">
                        Open in Keep
                        <span class="optionsDiv__OptionsImageDiv__moreOptionsDiv__moreOptions__keepImage">
                          <img src="./images/openKeepImage.svg" alt=""
                        /></span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; margin-top: 50px" class="text-center">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
          crossorigin="anonymous"></script>
     <!-- CareerOptionsRHS 1 -->
     <ins class="adsbygoogle"
          style="display:inline-block;width:300px;height:500px"
          data-ad-client="ca-pub-4835826878013634"
          data-ad-slot="2845389912"></ins>
     <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
     </script>
        </div>
        <div style="width: 100%" class="text-center">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
              crossorigin="anonymous"></script>
          <!-- CareerOptionsRHS 2 -->
          <ins class="adsbygoogle"
              style="display:inline-block;width:300px;height:500px"
              data-ad-client="ca-pub-4835826878013634"
              data-ad-slot="5200952917"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>
        <div style="width: 100%" class="text-center">           
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
              crossorigin="anonymous"></script>
              <!-- CareerOptionsRHS 3 -->
              <ins class="adsbygoogle"
              style="display:inline-block;width:300px;height:500px"
              data-ad-client="ca-pub-4835826878013634"
              data-ad-slot="3329265751"></ins>
              <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
        </div>
        <div style="width: 100%" class="text-center">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                crossorigin="anonymous"></script>
            <!-- CareerOptionsRHS 4 -->
            <ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:500px"
                data-ad-client="ca-pub-4835826878013634"
                data-ad-slot="4732280096"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>
        <div style="width: 100%" class="text-center">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
     crossorigin="anonymous"></script>
          <!-- CareerOptionsRHS 5 -->
          <ins class="adsbygoogle"
              style="display:inline-block;width:300px;height:500px"
              data-ad-client="ca-pub-4835826878013634"
              data-ad-slot="7166871748"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>
        <div style="width: 100%" class="text-center">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4835826878013634"
                crossorigin="anonymous"></script>
          <!-- CareerOptionsRHS 6 -->
          <ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:500px"
                data-ad-client="ca-pub-4835826878013634"
                data-ad-slot="7451481196"></ins>
          <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>
      </div>
    </div>
  </div>

  <!--This is added for the Demo purpose-->
  <!-- <ngx-spinner bdColor="rgba(51,51,51,0.9)" size="small" color="#fff">
    <div style="text-align: center;">
      <img src="{{IMAGES.CF_LOGO}}" alt="" class="pb-5" />
      <p style="font-size: 8px; color: white">Loading..</p>
    </div>
  </ngx-spinner> -->
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.9)"
    size="medium"
    color="#fff"
    [fullScreen]="true"
    template="<img style='width: 150px;' src='{{ IMAGES.CF_LOADER }}' />"
  >
    <p style="color: white">Loading...</p>
  </ngx-spinner>

  <cf-feedback *ngIf="showShare" (cancelOutput)="cancelPopup($event)"></cf-feedback>
</div>


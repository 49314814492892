import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { API_CONSTANATS } from '../../../shared/api.constants';
import { IMAGE_CONSTANTS } from '../../../shared/imageConstants';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  apiBaseUrl = environment.serverUrl;
  apiCMSBaseUrl = environment.cmsUrl;
  IMAGES: any = IMAGE_CONSTANTS;
  constructor(private http: HttpClient) { }
  registerStudent(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENTREGISTER}`, data);
  }
  loginCheck(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_LOGIN}`, data);
  }
  generateOTP(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_GENERATE_OTP}`, data);
  }

  validateOTP(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_VALIDATE_OTP}`, data);
  }

  updatePassword(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_UPDATE_PASSWORD}`, data);
  }

  setPassword(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_SET_PASSWORD}`, data);
  }

  validateInvitation(data): Observable<any> {
    console.log(data);
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_INVITATION}`, data);
  }
  landingPageDataPages(): Observable<any> {
    return this.http.get(`${this.apiCMSBaseUrl}/${API_CONSTANATS.STUDENT.CMS_API.LANDING_PAGE}/1`, {});
  }
  landingPageData(): Observable<any> {
    return of({
      status: '1',
      data: {
        careerPlatforms: [
          {
            image: this.IMAGES.CAREERPLATFORM,
            title: '#1 Career Platform',
            subText:
              'Worlds most comprehensive career suitability platform which measures your 175 behaviours & your suitability to 700+ careers',
          },
          {
            image: this.IMAGES.CAREERENCYCLOPEDIA,
            title: 'Career Encyclopedia',
            subText: 'Get complete career related information for 700+ careers',
          },
          {
            image: this.IMAGES.CAREERDASHBOARD,
            title: 'Career Dashboard',
            subText:
              'Get your personalized & live Career Dashboard which will help you to make the right career choices',
          },
          {
            image: this.IMAGES.YOUR_GREATEST_STRENGTH,
            title: 'Your Greatest Strengths',
            subText:
              'Get personalized list of your own greatest strengths. Your most suitable careers are matched to these strengths',
          },
          {
            image: this.IMAGES.DISCOVER,
            title: '#Discover Careers For Yourself',
            subText:
              'Discover from 600+ which careers you are suitable for, as well as unsuitable for, in the most comprehensive manner possible in the world today',
          },
        ],
        personalityList: [
          {
            name: 'Dentist',
            types: ['Analytical', 'Precise', 'Diplomatic', 'Public Contact'],
            image: this.IMAGES.DENTIST,
          },
          { name: 'Chef', types: ['Food', 'Artistic', 'Organized', 'Experimenting'], image: this.IMAGES.CHEF },
          {
            name: 'Financial Analyst',
            types: ['Finance', 'Numerical', 'Research & Learning', 'Systematic'],
            image: this.IMAGES.ANALYST,
          },
          {
            name: 'Film Director',
            types: ['Artistic', 'Takes Initiative', 'Open / Reflective', 'Planning'],
            image: this.IMAGES.FILM_DIRECTOR,
          },
          {
            name: 'Software Developer',
            types: ['Organized', 'Systematic', 'Wants Challenge', 'Analyzes Pitfalls'],
            image: this.IMAGES.SOFTWARE,
          },
          {
            name: 'Mobile Apps Developer',
            types: ['Analytical', 'Problem Solving', 'Collaborative', 'Takes Initiative'],
            image: this.IMAGES.APP_DESIGNER,
          },
        ],
        unlockSuccessful: [
          {
            series: '01',
            title: 'Take Career Test',
            subTextOne:
              'We assess your complete personality, areas of passion, your genuine strengths, your lowest preferences,',
            subTextTwo: 'To Learn What Makes You UNIQUELY YOU, Through Our Smart Questionnaire Technology.',
          },
          {
            series: '02',
            title: 'Get Career Match',
            subTextOne:
              'We match your complete personality to 700+ careers from all the industries. All the functions & specializations.',
            subTextTwo:
              'We use Artificial Intelligence, Behavioural Measurements and 30 years of Workplace Research to do this.',
          },
          {
            series: '03',
            title: 'Career Dashboard',
            subTextOne: 'Our Stream Selector & Career Dashboard provides all the information you need per career,',
            subTextTwo:
              'and helps you to clarify, shortlist and choose the best stream and careers mating your personality.',
          },
          {
            series: '04',
            title: 'Manage Your Career',
            subTextOne: 'Manage your complete career related needs through your single login.',
            subTextTwo: '',
          },
        ],
        careerStages: [
          {
            image: this.IMAGES.FE_FOUR,
            title: 'Working Professionals',
            subText:
              'Reflect upon your past experiences and future goals, and learn what makesBe your best self at work. Learn what makes you unique and how well-suited you are to your past, current, and future career choices. you unique.',
          },
          {
            image: this.IMAGES.FE_THREE,
            title: 'College Students & Graduates',
            subText:
              ' Unsure about what to do after college? See the range of careers you can pursue with your interests, personality, and education.',
          },
          {
            image: this.IMAGES.FE_TWO,
            title: 'Career Changers',
            subText:
              'Looking to make a career change? Thinking about going back to school? CareerExplorer will point you in the right direction.',
          },
          {
            image: this.IMAGES.FE_ONE,
            title: 'High School Students',
            subText:
              'Discover your true potential and all of the options you have after high school. Then see which path is right for you.',
          },
        ],
        greatThingsList: [
          {
            image: this.IMAGES.GTONE,
            quote: 'Our career algorithms are based on 30+ years of Workplace Performance Research.',
            title: '- Sai Kumar',
            backImage: '',
            subText: '10th Std',
            disabledClass: 'userQuotesFade',
          },
          {
            image: this.IMAGES.GTTWO,
            quote:
              'I like how in-depth it was. Not just, what are you interested in? but how your personality and personal preferences play into a career fit.',
            title: '- Sai Kumar',
            subText: '10th Std',
            backImage: this.IMAGES.YELLOW_HALF_CIRCLE,
            disabledClass: '',
          },
          {
            image: this.IMAGES.GTTHREE,
            quote:
              'We have been using Career Explorer with our college students for years. Students express how much they learn from this assessment, both about their interests and themselves. 5 stars!',
            title: '- Sai Kumar',
            subText: '10th Std',
            backImage: '',
            disabledClass: 'userQuotesFade',
          },
        ],
        pricesList: [
          {
            smileImage: this.IMAGES.PRICING,
            footerImage: this.IMAGES.BLUE_CLOUDES,
            pricingBackground: '',
            priceStyling: '',
            title: 'Careers Pack',
            price: '2000',
            tags: [
              [
                ' Technical Careers 336 Careers ₹1000',
                'Medical & Healthcare Science, Engineering, Communication Related Technology, Hardware Manufacturing Technology, Software & Services, Manufacturing – Consumer Goods (Except Food), Manufacturing – Food, Manufacturing – Industrial Goods, Basic Materials, Construction and Related Manufacturing',
              ],
              [
                'Non-Technical Careers 336 Careers ₹1000',
                'Finance, Sales, Marketing, Public Relations, Human Resources, Customer Service, Retail, Hospitality, Upper Management Services – Broadcasting & Entertainment, Services - Publishing Services – Business & Professional, Services – Personal & Home Services – Transportation & Travel Education, Utilities Government, Office and Administrative Support',
                'Career Test',
                'Your Suitability to selected careers',
                'Stream Selector Dashboard for career direction',
                'Career Management Dashboard',
                'Career Details of selected careers',
              ],
            ],
          },
          {
            smileImage: this.IMAGES.PRICING,
            footerImage: this.IMAGES.BLUE_CLOUDES,
            pricingBackground: '',
            priceStyling: '',
            title: 'Personality Pack',
            price: '1000',
            tags: [
              [
                'Includes',
                'Your Personality Report',
                'Your Greatest Strengths Report',
                '1-hour personality counselling',
                'Career Test',
              ],
            ],
          },
          {
            smileImage: this.IMAGES.PRICING,
            footerImage: this.IMAGES.BLUE_CLOUDES,
            pricingBackground: '',
            priceStyling: '',
            title: 'All Careers + Personality Reports',
            price: ' 2800',
            tags: [
              [
                'Includes',
                'Career Test',
                'Your Suitability to 700+ careers',
                'Stream Selector Dashboard for career direction',
                'Career Details of 700+ career',
                'Your Personality Report',
                'CCareer Management Dashboard',
                'Your Greatest Strengths Report',
                '1-hour personality counselling',
              ],
            ],
          },
          {
            smileImage: this.IMAGES.PRICING2,
            footerImage: this.IMAGES.YELLOW_CLOUDES,
            pricingBackground: this.IMAGES.COLORED_STARS,
            priceStyling: 'card4_bg',
            title: 'Prime Pack',
            price: '14,000',
            tags: [
              [
                'Includes',
                'Your Complete Career Pack – 700+ Careers',
                'Your Suitability % to 700+ careers',
                'Stream Selector Dashboard for career direction',
                'Career Management Dashboard',
                'Your Suitability % to 700+ careers',
                'Your Suitability % to 700+ careers',
                'Your Greatest Strengths Report',
                'Education Planner',
                'College Selection Counselling',
                'College Application Counselling – National and International colleges',
                'Study Abroad Counselling',
                'Education Funding Counselling',
                'Unlimited Career Counselling for 1 year',
              ],
            ],
          },
        ],
        trustUs: [
          {
            title: '700+ Career Specializations in 24 Function & Industries',
            subText:
              'You name the career and we have your Suitability Score % for it! Any career. Any stream. Any industry. Any function.',
          },
          {
            title: 'Based On 30 Years Of Research',
            subText: 'Our career algorithms are based on 30+ years of Workplace Performance Research.',
          },
          {
            title: 'QUESTIONNAIRE WITH RELIABILITY SCORE %',
            subText:
              'Our Smart Questionnaire checks for Reliability Score % indicating if your answers were reliable. 2,500,000 people have undertaken this questionnaire in 60 countries.',
          },
          {
            title: 'Built By Expert Behavioural Scientistss',
            subText:
              'Clear Futurz career platform is build using the expertise of highly experienced behavioural scientists, using latest assessment, research & database technologies.',
          },
        ],
      },
    });
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cf-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

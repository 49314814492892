import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_CONSTANATS } from '../../../shared/api.constants';
import { HttpService } from '@app/services/http.service';
import { CurrentUserService } from '@app/services/currentUser.service';
@Injectable({
  providedIn: 'root',
})
export class StudentService {
  apiBaseUrl = environment.serverUrl;
  zipCodeBaseUrl = environment.pinCodeAPI;
  constructor(private http: HttpClient, private httpService: HttpService, private cuser: CurrentUserService) { }

  lookupPincode(zipCode): Observable<any> {
    return this.http.get(`${this.zipCodeBaseUrl}${zipCode}`, {});
  }

  getSampleExcel(data?): Observable<any> {
    let currUser: any = this.cuser.getCurrentUserDetails;
    if (data) {
      return this.httpService.sendPost(`${API_CONSTANATS.ADMIN.COURSES_SAMPLE_EXCEL}`, currUser, true, '', 'blob');
    } else {
      return this.httpService.sendPost(`${API_CONSTANATS.ADMIN.STUDENT_SAMPLE_EXCEL}`, '', true, '', 'blob');
    }
  }

  saveStudentDetails(studentDetails): Observable<any> {
    return this.httpService.sendPost(`${API_CONSTANATS.ADMIN.SAVE_STUDENT}`, studentDetails, true);
  }
  validateUploadSheet(data, courses?): Observable<any> {
    return this.httpService.sendPost(
      `${courses ? API_CONSTANATS.ADMIN.VALIDATE_COURSE_UPLOAD : API_CONSTANATS.ADMIN.VALIDATE_UPLOAD}`,
      data,
      true,
      '',
      'blob'
    );
  }
  bulkUpload(data, courses?): Observable<any> {
    const url = `${this.apiBaseUrl}/${!courses ? API_CONSTANATS.ADMIN.STUDENT_BULK_UPLOAD : API_CONSTANATS.ADMIN.COURSE_BULK_UPLOAD
      }`;
    const headers: HttpHeaders = new HttpHeaders().set(
      'X-Access-Token',
      `${JSON.parse(localStorage.getItem('token'))}`
    );
    return this.http
      .post<any>(url, data, {
        headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          console.log('event', event, HttpEventType.UploadProgress, HttpEventType.Response);
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };

            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  fetchStudentList(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.STUDENT_LIST, true);
  }

  sendQuestionary(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_STUDENT_QUESTIONARY, inputParams, true);
  }
  getCity(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.CITY, inputParams, true);
  }
  getBatch(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.CLASS, inputParams, true);
  }
  getBranch(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.BRANCH, inputParams, true);
  }
  getSection(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.SECTION, inputParams, true);
  }
  fetchStudentProfile(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.VIEW_STUDENT, inputParams, true);
  }
  saveStudentActivities(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.SAVE_STUDENT_ACTIVITY, inputParams, true);
  }
  fetchStudentActivities(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.STUDENT_ACTIVITY_LIST, inputParams, true);
  }
  updateStudentProfile(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_STUDENT_PROFILE, inputParams, true);
  }
  updateStudentStatus(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_STUDENT_STATUS, inputParams, true);
  }

  getPostData(url, inputParams): Observable<any> {
    return this.httpService.sendPost(url, inputParams, true);
  }

  getGetData(url): Observable<any> {
    return this.httpService.sendGet(url, true);
  }

  getReports(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_REPORTS, true);
  }

  getFilteredList(reportType): Observable<any> {
    let url = `${API_CONSTANATS.ADMIN.STUDENT_LIST}/Completed/${reportType}`;
    return this.httpService.sendGet(url, true);
  }
  saveGenerateReports(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GENERATE_REPORTS, inputParams, true);
  }

  saveGenerateSingleReport(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_SINGLE_REPORT, inputParams, true);
  }

  getCustomerCareer(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_CUSTOMER_CAREER, true);
  }
  updateNoteColor(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.CANDIDATE_NOTE_COLOR, inputParams, true);
  }
  getStudentNotifications(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_STUDENT_NOTIFICATIONS, true);
  }
  getStudentAvailableReports(id): Observable<any> {
    let url = `${API_CONSTANATS.ADMIN.STUDENT_REPORT}/${id}`;
    return this.httpService.sendGet(url, true);
  }
  addInstituteTiltleExists(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.ADD_INSTITUTE_TILE_EXISTS, true);
  }
  getCareerEnjoymentReports(reportType): Observable<any> {
    let url = `${API_CONSTANATS.ADMIN.STUDENT_LIST}/Completed/${reportType}`;
    return this.httpService.sendGet(url, true);
  }
  getstudentReportsList(id): Observable<any> {
    let url = `${API_CONSTANATS.ADMIN.STUDENT_REPORT_LIST}/${id}`;
    return this.httpService.sendGet(url, true);
  }
  deleteStudent(id): Observable<any> {
    let url = `${API_CONSTANATS.ADMIN.DELETE_STUDENT}/${id}`;
    return this.httpService.sendGet(url, true);
  }
  uploadReports(formData): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPLOAD_REPORT, formData);
  }

}

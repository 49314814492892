import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

const ENCRYPT_KEY = '#B!tC@$';

export const Keys = {
  user_session: '_UD_',
};

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private Listners = new Subject<any>();

  sharedVal: any = {};
  constructor() {}

  listen(): Observable<any> {
    return this.Listners.asObservable();
  }

  call(filterBy: string): any {
    this.Listners.next(filterBy);
  }

  setItem(key, value): any {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key): any {
    const value = localStorage.getItem(key) || undefined;
    if (value !== undefined && value !== 'undefined' && value !== null) {
      return JSON.parse(value);
    }
  }

  deleteItem(key): any {
    localStorage.removeItem(key);
  }

  clear(): any {
    localStorage.clear();
  }
  getData(): any {
    return this.sharedVal;
  }
  setStatusOfUser(key, value): any {
    const currentData = this.getItem('userData');
    currentData.questionnaire_status = value;
    this.setItem('userData', currentData);
  }
}

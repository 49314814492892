// pipes.module.ts
// other imports
import { NgModule } from '@angular/core';
import { FilterPipe} from './../core/pipes/filter.pipe';
import { PaymentComponent } from './student/payment/payment.component';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    FilterPipe, PaymentComponent
  ],
  exports: [
    FilterPipe,
    PaymentComponent
  ]
})
export class PipesModule {}
<!-- <ngx-spinner bdColor="rgba(51,51,51,0.9)" size="medium" color="#fff">
  <div class="mainContent">
    <img src="{{IMAGES.CF_LOADER_CUSTOM}}" alt="" class="pb-5" />
    <p class="subText">Loading..</p>
  </div>
</ngx-spinner>
 -->

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.9)" size = "medium" color = "#fff"  [fullScreen] = "true"
template="<img style='width: 150px;' src='{{IMAGES.CF_LOADER}}' />"
>
  <p style="color: white;" > Loading... </p>
</ngx-spinner>
import { Directive, OnInit, Input } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthServiceService } from "@app/services/authService.service";
import { Role } from "@app/core/models/role";
@Directive({
  selector: '[appUserRole]'
})
export class UserRoleDirectiveDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthServiceService,
    private viewContainer: ViewContainerRef
) { }

userRoles: Role[];

@Input() 
set appUserRole(roles: Role[]) {
    if (!roles || !roles.length) {
        throw new Error('Roles value is empty or missed');
    }

    this.userRoles = roles;
}

ngOnInit() {
    let hasAccess = false;

    if (this.authService.isAuthorized() && this.userRoles) {
     // console.log('hasAccess',this.userRoles)
        hasAccess = this.userRoles.some(r => this.authService.hasRole(r));
    }
   
    if (hasAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
        this.viewContainer.clear();
    }
}
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../imageConstants';
import { ROUTER_CONSTANATS } from '../../../shared/routerUrl.constants';
@Component({
  selector: 'cf-back-arrow',
  templateUrl: './back-arrow.component.html',
  styleUrls: ['./back-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackArrowComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  constructor() {}
  ROUTING: any = ROUTER_CONSTANATS;
  ngOnInit(): void {}
}

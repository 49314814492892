import { Directive, OnInit, Input } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthServiceService } from "@app/services/authService.service";
@Directive({
  selector: '[cfUserPermission]'
})
export class UserPermissionDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>,
    private authService: AuthServiceService,
    private viewContainer: ViewContainerRef) { }
    userRoles: any;

@Input() 
set cfUserPermission(roles: any) {
    if (!roles || !roles.length) {
        throw new Error('Roles value is empty or missed');
    }
   // console.log('hasspermission', roles)
    this.userRoles = roles;
}
  ngOnInit() {
    let hasAccess = false;
    
      hasAccess= (this.authService.getUserRole() !== 'GlobalAdmin')? this.authService.isHavingPermission(this.userRoles[0]): false
    
  //  console.log('haspermission', this.authService.getUserRole() , hasAccess)
    if (hasAccess) {
      this.viewContainer.clear();
  } else {
      
      this.viewContainer.createEmbeddedView(this.templateRef);
  }
  }
}

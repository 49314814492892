import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { API_CONSTANATS } from '../shared/api.constants';
@Injectable({
  providedIn: 'root',
})
export class QualificationsService {
  apiUrl = environment.cmsUrl;
  constructor(private http: HttpClient) {}
  getQualifications(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/${API_CONSTANATS.STUDENT.CMS_API.CAREER_QUALIFICATIONS + '?_sort=DisplayOrder:ASC'}`, data);
  }
}

<div class="termscontainer">
  <div class="termsform">
    <div class="term-head">
      <h2>About this System</h2>
    </div>
    <div class="term-body">
      <div *ngFor="let terms of aboutData">
        <div *ngFor="let term of terms.Sections">
          <h3>{{ term.Title }}</h3>
          <h4>{{ term.SubTitle }}</h4>
          <p class="content">{{ term.Content }}</p>
        </div>
      </div>
    </div>
    <div class="term-footer">
      <button class="btn btn-text" (click)="cancel()">Done</button>
    </div>
  </div>
</div>

<div ngbDropdown class="d-inline-block reportsDropDown">
  <div class="" id="dropdownBasic1" ngbDropdownToggle (click)="getUpdatedReport()">
    {{selectedStream ? selectedStream : 'Dashboard'}} <img src="{{IMAGES.DOWN_ARROW}}" class="reportsDropDown__arrow" />
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="reportsDropDown__category">
    <span ngbDropdownItem *ngFor="let item of dropDownList; index as i">
      <div class="" *ngIf="item.link!=='/yourCounsellor' || !checkPermission('register')">
        <span class="moreDetails" *ngIf="item.isPurchased===true"
          (click)="openReadMore(readMore,item.name)">{{item.details}}</span>
        <span class="moreDetails" *ngIf="item.isPurchased===false"><a *ngIf="item.name!=='Cc'"
            (click)="paymentprocess(payment,item.name,item.text)">{{item.buyNow}}</a><span
            *ngIf="item.name==='Cc'">{{item.buyNow}}</span><span class="moreDetailsSub"
            (click)="openReadMore(readMore,item.name)">Know more</span></span>
        <a *ngIf="item.isPurchased===true" class="reportsDropDown__category__reportName"
          href="{{item.link}}">{{item.text}}</a>
        <a *ngIf="item.isPurchased===false && item.name!=='Cc'" class="reportsDropDown__category__reportName notActive"
          (click)="paymentprocess(payment,item.name,item.text)">{{item.text}}</a>
        <a *ngIf="item.isPurchased===false && item.name==='Cc'"
          class="reportsDropDown__category__reportName" routerLink="/careersList">{{item.text}}</a>
        <hr *ngIf="i===7" />
      </div>
    </span>
  </div>
</div>
<ng-template #payment let-modal>
  <div class="headerSection">
    <div class="payment">
      <form #form action="https://test.payu.in/_payment" id="payment_form" method="Post">
        <div class="cartHeading">Order Confirmation</div>
        <input type="hidden" id="udf5" name="udf5" value="{{paymentdata.udf5}}" />
        <input type="hidden" id="surl" name="surl" value="{{paymentdata.surl}}" />
        <input type="hidden" id="furl" name="furl" value="{{paymentdata.furl}}" />
        <input type="hidden" id="curl" name="curl" value="{{paymentdata.curl}}" />
        <input type="hidden" id="key" name="key" value="{{paymentdata.key}}" />
        <!-- <div><span>Order ID&nbsp;:</span><span>&nbsp;{{paymentdata.txnid}}</span></div>
        <div><span>Amount&nbsp; :</span><span>&nbsp;{{paymentdata.amount}}</span></div> -->
        <div class="cartItemHeading">
          <div class="reportname">{{description}}</div> 
          <div class="amount">{{paymentdata.amount}}</div>
        </div>
        <input type="hidden" id="txnid" name="txnid" placeholder="Transaction ID" value="{{paymentdata.txnid}}" />
        <input type="hidden" id="amount" name="amount" placeholder="Amount" value="{{paymentdata.amount}}" />
        <input type="hidden" id="productinfo" name="productinfo" placeholder="Product Info"
          value="{{paymentdata.productinfo}}" />
        <input type="hidden" id="firstname" name="firstname" placeholder="First Name"
          value="{{paymentdata.firstname}}" />
        <input type="hidden" id="Lastname" name="Lastname" placeholder="Last Name" value="" />
        <input type="hidden" id="email" name="email" placeholder="Email ID" value="{{paymentdata.email}}" />
        <input type="hidden" id="phone" name="phone" placeholder="Mobile/Cell Number" value="{{paymentdata.phone}}" />
        <input type="hidden" id="address1" name="address1" placeholder="Address1" value="" />
        <input type="hidden" id="address2" name="address2" placeholder="Address2" value="" />
        <input type="hidden" id="city" name="city" placeholder="City" value="" />
        <input type="hidden" id="state" name="state" placeholder="State" value="" />
        <input type="hidden" id="country" name="country" placeholder="Country" value="" />
        <input type="hidden" id="Zipcode" name="Zipcode" placeholder="Zip Code" value="" />
        <input type="hidden" id="Pg" name="Pg" placeholder="PG" value="CC" />
        <input type="hidden" id="hash" name="hash" placeholder="Hash" value="{{paymentdata.hash}}" />
        <div id="alertinfo" class="dv"></div>
        <div class="payment-head">
          <div>
            <input type="button" value="Cancel" class="button-cnl" (click)="closeModal()" />
            <input type="submit" value="Confirm" class="btn checkoutButton" (click)="form.submit()"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>


<ng-template #readMore let-modal class="yourMenu__readMore">
  <div class="yourMenu__readMore__main">
    <div class="row">
      <div class="col-12">
        <div class="yourMenu__readMore__main__header ">
          <span class="yourMenu__readMore__main__dismissModal" (click)="modal.dismiss('Cross click')"
            aria-hidden="true">Close</span></div>
      </div>
      <div class="col-12 yourMenu__readMore__main__popupContent">
        <!-- <ng-container *ngFor="let item of responseData?.report_info?.knowMore"> -->
        <div class="yourMenu__readMore__main__knowMoreHeading">
          Welcome to {{reportsKnowMoreTitle}}
        </div>
        <div class="yourMenu__readMore__main__knowMoreDescription">
          {{reportsKnowMoreBody}}
        </div>
        <!-- </ng-container> -->
      </div>
    </div>
  </div>
</ng-template>

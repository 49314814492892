<div class="Share">
  <div class="Share__shareHeader">
      <div class="row no-gutters align-items-center" >
          <div class="col-6 col-md-6 col-lg-6"><h1 class="Share__shareHeader__shareMainHeading">Share </h1></div>
          <div class="col-6 col-md-6 col-lg-6 text-right"><img src="./images/close.svg" alt="" class="Share__shareHeader__closeImage"></div>
      </div>
  </div>
  <div class="Share__shareHeadingContainer">
      <div class="Share__shareHeadingContainer__shareCaption">Share Your </div>
      <div class="Share__shareHeadingContainer__shareCaption">Career Option Report with</div>
  </div>

  <div class="Share__shareOptionsDiv text-center">
      <a href="" class="Share__shareOptionsDiv__shareImages"><img src="./images/whatsappShare.png" alt="" class=""></a>
      <a href="" class="Share__shareOptionsDiv__shareImages"><img src="./images/FaceBookShare.png" alt=""></a>
      <a href="" class="Share__shareOptionsDiv__shareImages"><img src="./images/InstagaramShare.png" alt="" class=""></a>
      <h6 class="Share__shareOptionsDiv__Or">Or</h6>
  </div>
  <div class="Share__Email">
      <div class="form-group">
          <label for="exampleInputEmail1 " class="Share__Email__emailHeading">Email </label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="2 people 3rd cursor">
        </div>
  </div>

  <button class="btn sendBtn"> Send</button>
 </div>
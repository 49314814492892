import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { Career } from '../../../core/models/career';
import { CategoriesService } from '../../../services/categories.service';
import { ExtractReportService } from '../../../services/extract-report.service';
import { StorageService } from '../../../services/storage.service';
import { API_CONSTANATS } from '@app/shared/api.constants';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from '../../../services/resources.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'cf-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Output() searchData = new EventEmitter<any>();
  @Output() fromCourses = new EventEmitter<any>();
  @Output() forEachValForCourse = new EventEmitter<any>();
  @Output() forResetCourse = new EventEmitter<any>()
  // @Output('keyup') keyup = new EventEmitter<any>();
  @Input() ForCoursesList: string;
  searchOptions: any = [];
  categorylistbackup = [];
  careersList: Career[];
  forCoursesSearch: any = '';
  searchOptionClicked: boolean = false;
  careers: Career[];
  page = 1;
  pageSize = 50;
  collectionSize: any;
  apiCmsUrl = environment.cmsUrl;
  courcesTab = false;
  public searchValue: string;
  public crossMark: boolean = false;

  constructor(
    private careerService: ExtractReportService,
    private storage: StorageService,
    public route: ActivatedRoute,
    public router: Router,
    private resourcesService: ResourcesService,
    private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef, private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    // console.log(this.route.snapshot, this.route, this.router, )
    this.courcesTab = this.router.url.includes('/courses') ? true : false;
    console.log(this.router.url, this.courcesTab, "coursesTAb")
    this.activatedRoute.queryParams.subscribe(paramDAta => {
      console.log(paramDAta.search, "paramDAtasEarch")
      if (paramDAta.search != undefined && paramDAta.search && paramDAta.search != "" && this.courcesTab) {
        this.searchValue = paramDAta.search;
      }
    })
    if (!this.courcesTab)
      this.getCareerList();
  }
  ngOnChanges(data?) {
    //   this.forCoursesSearch = this.ForCoursesList;
    //   if (this.forCoursesSearch == '' && this.courcesTab == true) {
    //     this.searchOptionClicked = false;
    //   } else if (this.forCoursesSearch.length > 2 && this.courcesTab == true) {
    //     console.log(this.forCoursesSearch, 'gettingDatafromSEarch');
    //     // this.searchOptions = this.searchStaticObj
    //     if(data.ForCoursesList.currentValue.length>2){
    //     let searched_data_for_courses = {
    //       search: this.forCoursesSearch,
    //     };
    //     this.resourcesService.getListedOfSearched(searched_data_for_courses).subscribe((return_val) => {
    //       this.searchOptions = return_val.result;
    //       console.log(return_val.result);
    //     });
    //   }
    // }
    console.log()
  }
  eventVal = '';
  selectedIndex = 0;

  onKeyup(evt: any): any {
    if (this.courcesTab == true) {
      this.eventVal = evt.target.value;
      if (this.eventVal.length > 0) {
        this.searchOptionClicked = false;
      } else if (this.eventVal.length == 0) {
        this.eventVal = ''
        this.forEachValForCourse.emit(evt);
        this.searchOptionClicked = true;
        this.searchOptions = [];
        console.log(this.searchOptionClicked, 'seachOptioClicked');
      }
      console.log(this.eventVal.length, 'consoling eventVal');
    }
    // if(this.forCoursesSearch != ""){
    //   // if(this.forCoursesSearch.length > 2){
    //   //   // this.keyup.emit('');
    //   //   // let searched_data_for_courses = {
    //   //   //   search:this.forCoursesSearch
    //   //   // }
    //   //   // this.resourcesService.getListedOfSearched(searched_data_for_courses).subscribe(return_val => {
    //   //   //   this.searchOptions = return_val.result
    //   //   // })
    //   //     console.log(this.forCoursesSearch,"onKeyup for curse")
    //   // }

    // }
    // new try
    if (this.forCoursesSearch == '' && this.forCoursesSearch?.length == 0 && !this.courcesTab) {
      // if(evt.keyCode == ){

      // }
      if (evt.keyCode == 38) {
        if (this.selectedIndex > 0) {
          this.selectedIndex -= 1;
          this.searchValue = this.searchOptions[this.selectedIndex].description
            ? this.searchOptions[this.selectedIndex].description
            : this.searchOptions[this.selectedIndex].categoryName;
          this.scrolldiv();
          // console.log(scrollTop)
        }
      }
      if (evt.keyCode == 27) {
        console.log("event esc triggered")
        // this.searchOptionClicked = true;
      }
      if (evt.keyCode == 40) {
        if (this.selectedIndex < this.searchOptions.length) {
          this.selectedIndex += 1;
          this.searchValue = this.searchOptions[this.selectedIndex].description
            ? this.searchOptions[this.selectedIndex].description
            : this.searchOptions[this.selectedIndex].categoryName;
          // console.log(scrollTop)
          this.scrolldiv();
        }
      }
      this.crossMark = true;
      if (!evt.target.value) {
        this.crossMark = false;
        this.searchData.emit('');
        this.searchOptions = [];
      } else if (evt.target.value && evt.keyCode != 40 && evt.keyCode != 38) {
        const searchTerm = evt.target.value.trim().toLowerCase();
        // this.searchData.emit('');

        this.searchOptions = [
          ...this.categorylistbackup.filter((cat) => cat.categoryName.toLowerCase().indexOf(searchTerm) !== -1),
          ...this.careersList.filter((cat) => cat.description.toLowerCase().indexOf(searchTerm) !== -1),
        ];
      }
    } else {
      if (evt.keyCode == 8) {
        // this.selectedIndex = 0;
        this.selectedIndex = -1;
      }
      if (evt.keyCode == 13) {
        let data = {
          'course_name': this.searchValue
        }
        this.onSelectFilter(data)
      }
      if (evt.keyCode == 27) {
        console.log("event esc triggered")
        this.eventVal = ''
        this.searchValue = ""
        this.searchOptionClicked = true;
        this.forResetCourse.emit()
      }
      if (evt.keyCode == 38) {
        if (this.selectedIndex > 0) {
          console.log(this.selectedIndex, this.searchOptions.length - 1);
          this.selectedIndex -= 1;
          this.searchValue = this.searchOptions[this.selectedIndex]?.description && this.searchOptions[this.selectedIndex]?.description != "" && this.searchOptions[this.selectedIndex]?.description != null
            ? this.searchOptions[this.selectedIndex].description
            : this.searchOptions[this.selectedIndex].course_name;
          // console.log(scrollTop)
        }
        this.scrolldiv();
      }
      if (evt.keyCode == 40) {
        console.log(this.selectedIndex, this.searchOptions.length - 1);
        if (this.selectedIndex < this.searchOptions.length - 1) {
          this.selectedIndex += 1;
          this.searchValue = this.searchOptions[this.selectedIndex].description
            ? this.searchOptions[this.selectedIndex].description
            : this.searchOptions[this.selectedIndex].course_name;
          // console.log(scrollTop)
          this.scrolldiv();
        }
      }
      if (((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 65 && evt.keyCode <= 90)) && (this.courcesTab && this.eventVal.length > 2)) {
        this.searchOptionClicked = false;
        let searched_data_for_courses = {
          search: this.eventVal,
        };
        this.spinner.show()
        this.resourcesService.getListedOfSearched(searched_data_for_courses).subscribe((return_val) => {
          this.spinner.hide()
          this.searchOptions = return_val.result;
          console.log(return_val.result);
          this.selectedIndex = -1;
          this.cdr.detectChanges();
        });
      }
    }
  }
  cancelSearch(){
    this.eventVal = ''
    this.searchValue = ""
        this.searchOptionClicked = true;
        this.forResetCourse.emit()
  }
  scrolldiv() {
    let el = 'headerSearchOp' + this.selectedIndex;
    let scrolltop = document.getElementById(el).getBoundingClientRect();
    let overlow = document.getElementById('search-overflow');
    // console.log(document.getElementById(el).scrollHeight, document.getElementById(el).clientHeight, "////////", document.getElementById('search-overflow').scrollTop, document.getElementById('search-overflow').scrollHeight, document.getElementById('search-overflow').clientHeight, scrolltop)
    // document.getElementById(el).scrollTop = 0;
    console.log(
      scrolltop.top,
      document.getElementById('search-overflow').clientHeight,
      scrolltop.top > document.getElementById('search-overflow').clientHeight,
      'scrolldata',
      document.getElementById(el).offsetTop
    );
    if (scrolltop.top - 10 > document.getElementById('search-overflow').clientHeight || scrolltop.top - 10 <= 0) {
      overlow.scrollTo({ top: document.getElementById(el).offsetTop, behavior: 'smooth' });
    }
    // overlow.scrollTo(0, overlow.offsetTop); //scrollTop = scrolltop.top //({ top: scrollTop.top + 50, left: 0, behavior: 'smooth' })
  }

  getCareerList() {
    const myData = this.storage.getItem('userData');
    const data = { student_id: myData.student_id, reportName: 'Co1' };
    /* ,'type':"careerList" */
    this.careerService.extractReportPDF(data).subscribe((res: any) => {
      if (res.status === 1) {
        this.careersList = res.data.report_data;
        console.log('>>>>>>>>>>', this.careersList);
        this.collectionSize = res.data.report_data.length;
      }
    });
  }

  refreshCountries(): any {
    this.careers = this.careersList
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

    console.log('>>>>>>>', this.careers);
  }

  isSearchDataEmpty(): any {
    this.collectionSize = this.careersList.length;
    this.refreshCountries();
  }
  onSelectFilter(filter: any): any {
    console.log(filter, "filterede triggerd")
    if (this.courcesTab == false) {
      console.log(filter, 'filteredDAta');
      this.searchData.emit(filter);
      this.searchValue = filter.description;
      this.careers = filter.description
        ? [...this.careersList.filter((cat) => cat.description === filter.description)]
        : [...this.careersList.filter((cat) => cat.categoryName === filter.categoryName)];
      this.collectionSize = this.careers.length;
      this.careers = this.careers
        .map((country, i) => ({ id: i + 1, ...country }))
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      this.searchOptions = [];
    } else {
      this.searchValue = filter?.course_name;
      this.fromCourses.emit(filter);
      console.log(filter, 'filter executed');
      this.searchOptionClicked = true;
    }
  }
  getStyle(option): any {
    return { background: option.color || '#' + Math.floor(Math.random() * 16777215).toString(16) };
  }

  // forCoursesPageFunc()
  forCoursesPageFunc(ForCoursesList) {
    console.log(ForCoursesList, 'data from search data');
  }
}

export default class Utils {
  /* Note: This global variable is using in multiple componets.
   * Like  CareerDetails,CareerOptions...etc components.
   * So make sure, that if you edit value here, it will reflect in remaining compoents.
   */
  static grades = {
    gradeC: { minimum: 0.0, maximum: 60.0 },
    gradeB: { minimum: 59.9, maximum: 75.0 },
    gradeA: { minimum: 74.9, maximum: 100.0 },
  };

  /* Note: we written below logic to display multiple images in single sliding purpose. */
  static transformDataForCarousel(totalList, itemsForEachCarousel) {
    let j = -1;
    const carouselDataFormat = [];
    for (let i = 0; i < totalList.length; i++) {
      if (i % itemsForEachCarousel === 0) {
        j++;
        carouselDataFormat[j] = [];
        carouselDataFormat[j].push(totalList[i]);
      } else {
        carouselDataFormat[j].push(totalList[i]);
      }
    }
    return carouselDataFormat;
  }
}

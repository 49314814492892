export const API_CONSTANATS = {
  STUDENT: {
    STUDENT_SET_PASSWORD: 'setStudentPassword',
    STUDENT_INVITATION: 'validateStudentInvitation',
    STUDENT_LOGIN: 'studentLogin',
    STUDENT_GET_HARRISON_URL: 'startHarissonTest',
    STUDENT_HARRISON_RESPONSE: 'checkHarissonResponse',
    STUDENT_GENERATE_OTP: 'generateOTP',
    STUDENT_VALIDATE_OTP: 'validateOTP',
    STUDENT_UPDATE_PASSWORD: 'updateStudentPassword',
    STUDENT_EXTRACT_REPORT: 'extractReportPDF',
    STREEM_SELECTOR_REPORT: 'streamSelectorReport',
    STUDENT_SHORT_LIST_CAREER: 'careerShortlistUpdate',
    STUDENT_DETAILS: 'getStudentDetails',
    STUDENT_NOTIFICATIONS: 'getNotificationsStudent',
    VIEWED_NOTIFICATIONS_STUDENT: 'viewedNotificationsStudent',
    STUDENT_REPORTS_KNOWMORE: 'reportsKnowmore',
    HAVE_ANY_ISSUE_INSERT: 'haveAnyIssueInsert',
    UPDATE_STUDENT_PROFILE: 'updateStudentProfile',
    GET_STUDENT_ISSUES_LIST: 'studentIssuesList',
    PAYMENT_HASH: 'getHashForPayment',
    PAYMENT_RESPONSE: 'paymentResponse',
    STUDENTREGISTER: 'studentRegister',
    PEOPLE_ALSO_LIKE: 'peopleAlsoLikeList',
    EACH_CAREER_BOOKS: 'eachCareerBooks',
    EACH_CAREER_VIDEOS: 'eachCareerVideos',
    VALIDATE_AND_UPDATE_STUDENT_PASSWORD: 'validateAndUpdateStudentPassword',
    GOOGLE_NEWS: 'getGoogleNews',
    BOOKS_BY_CAREER: 'getBooksbyCareer',
    COURSES_BY_CAREER: 'getCoursesbyCareer',
    VIDEOS_BY_CAREER: 'getVideosbyCareer',
    TOOLS_BY_CAREER: 'getToolsbyCareer',
    SAVE_CAREER_PROSPECT: 'saveCareerProspect',
    UPDATE_CAREER_INDEX: 'updateCareerIndex',
    CMS_API: {
      CAREER_DETAILS: 'career-details',
      TERMS_AND_CONDITIONS: 'pages',
      CAREER_QUALIFICATIONS: 'qualifications',
      CAREER_CATEGORIES: 'categories?_sort=categoryName:asc',
      LANDING_PAGE: 'landing-pages',
      CAREER_VIDEOS: 'career-videos',
      CAREER_TOOLS: 'career-tools',
      CAREER_NEWS: 'career-news',
      CAREER_BOOKS: 'career-books',
      CAREER_COURSES: 'courses',
      GET_TERMS_CONDITIONS: 'admin-pages?APIkey=termsAndConditions&adminType=',
      GET_ABOUT_SYSTEM: 'admin-pages?APIkey=aboutUs',
      ORGANISATION_SIZE: 'organisation-sizes',
      ORGANISATION_TYPES: 'organisation-types',
      INTRESTS: 'interests',
    },
    CMS_API_KEYS: {
      TERMS_AND_CONDITIONS: 'termsAndConditions',
    },
    FEATURE_ACCESS: 'studentFeatureAccess',
    LOAN_QUANLIFICATION: 'edu-loan-qualifications',
    COURSE_DURATION: 'edu-loan-course-durations',
    LOAN_AMOUNT: 'edu-loan-amounts',
    COURSE_MODE: 'edu-loan-mode-of-courses',
    SEND_FUNDS_EMAIL: 'sendFundsEmail'
  },
  ADMIN: {
    LOGIN: 'instituteLogin',
    FORGET_PASSWORD: 'sendForgetPasswordMail',
    FORGET_PASSWORD_OTP: 'sendForgetPasswordOTP',
    VALIDATE_OTP: 'validateAdminOTP',
    UPDATE_PASSWORD: 'updateAdminPassword',
    VALID_INSTITUTE: 'validateInstituteOTP',
    STUDENT_SAMPLE_EXCEL: 'generateStudentUploadExcel',
    SAVE_STUDENT: 'addStudent',
    STUDENT_BULK_UPLOAD: 'bulkStudentUpload',
    VALIDATE_UPLOAD: 'validateStudentUpload',
    UPDATE_STUDENT_QUESTIONARY: 'sendQuestionaire',
    STUDENT_LIST: 'getInstituteStudentsList',
    ACCOUNT_DETAILS: 'getAccountDetails',
    UPDATE_ACCOUNT_DETAILS: 'updateAccountDetails',
    UPDATE_SETTINGS_PASSWORD: 'validateAndUpdatePassword',
    GET_SYSTEM_SETTINGS: 'getSystemSettings',
    UPDATE_SYSTEM_SETTINGS: 'updateSystemSettings',
    UPDATE_ADVANCED_SETTINGS: 'updateAdvanceSettings',
    GET_ADVANCED_SETTINGS: 'getAdvanceSettings',
    UPDATE_GENDER_SETTINGS: '/updateGenderAgeRaceSettings',
    GET_GENDER_SETTINGS: 'getGenderAgeRaceSettings',
    GET_EMAIL_TEMPLATES: 'getEmailTemplates',
    INSERT_EMAIL_TEMPLATES: 'insertEmailTemplate',
    GET_USER_LIST: 'getUsersList',
    INSERT_INSTITUTE_USER: 'insertInstituteUser',
    UPDATE_INSTITUTE_USER: 'updateUsersDetails',
    DELETE_INSTITUTE_USER: 'deleteUsersDetailsByID',
    GET_INSTITUTE_USER_BY_ID: 'getUsersDetailsByID',
    GET_FEATURES: 'getFeatures',
    STATE: 'getInstituteStates',
    CITY: 'getInstituteCities',
    CLASS: 'getClassBatches',
    BRANCH: 'getInstituteBranches',
    SECTION: 'getBatchSections',
    VIEW_STUDENT: 'getStudentProfile',
    STUDENT_ACTIVITY_LIST: 'getStudentActivities',
    SAVE_STUDENT_ACTIVITY: 'addStudentActivity',
    UPDATE_STUDENT_PROFILE: 'updateStudentProfile',
    UPDATE_STUDENT_STATUS: 'updateStudentStatus',
    GET_REPORTS: 'getReports',
    CANDIDATE_NOTE_ADD: 'insertNote',
    CANDIDATE_NOTE_EDIT: 'updateNoteById',
    CANDIDATE_NOTE_COLOR: 'updateNoteColor',
    CANDIDATE_NOTE_LIST: 'getNotes',
    CANDIDATE_NOTE_DELETE: 'deleteNoteById',
    GET_CUSTOMER_CAREER: 'getCustomCareers',
    INSERT_CUSTOMER_CAREER: 'insertCustomCareers',
    INSERT_FEEDBACK: 'sendFeedback',
    GET_TRANSACTION_HISTORY: 'getTransactionHistory',
    STUDENT_REPORT: 'studentAvailableReports',
    LIST_ADMIN: 'adminMainList',
    PAYMENT_TYPES: 'paymentTypes',
    PRODUCT_TYPES: 'productTypes',
    GENERATE_REPORTS: 'generateReportForStudents',
    ORDER_WORKSHEET: 'orderWorkSheetList',
    UPDATE_ORDER_WORKSHEET: 'updateOrderWorksheet',
    ADD_INSTITUTE: 'addInstitute',
    EDIT_INSTITUTE: 'editInstitute',
    GET_INSTITUTE: 'getInstituteInfo',
    GET_COUNTRIES: 'countries',
    GET_STATES: 'states',
    GET_CITIES: 'cities',
    GET_INSTITUTE_INFO: 'getInstituteInfo',
    GET_SINGLE_REPORT: 'generateReportForSingleStudent',
    ADD_BRANCH: 'addBranch',
    EDIT_BRANCH: 'editBranch',
    LIST_BRANCH: 'listBranch',
    UPDATE_SIGNIN_TOKEN: 'updateToken',
    BRANCH_DETAILS: 'getbranchDetails',
    ALLOCATED_UNITS: 'getallocatedUnits',
    REQUEST_UNITS: 'requestUnits',
    DEPOSIT_UNITS: 'depositUnits',
    AllCountryAdminList: 'countryAdminList',
    GetResellerCountryList: 'getResellerAdminsByCountryId',
    GetInstituteByReseller: 'getInstitutesByResellerId',
    insertPermissions: 'insertAdminPermissions',
    insertNotifications: 'insertNotifications',
    getStateByCountry: 'states',
    getCityByState: 'cities',
    GET_NOTIFICATIONS: 'getNotifications',
    GET_NOTIFICATIONS_USERS: 'getNotificationsHeader',
    GET_STUDENT_NOTIFICATIONS: 'getNotificationsStudentHeader',
    VIEWED_NOTIFICATIONS: 'viewedNotifications',
    ADD_INSTITUTE_TILE_EXISTS: 'addInstituteTiltleExists',
    DELETE_STUDENT: 'deleteStudent',
    STUDENT_REPORT_LIST: 'studentReportsList',
    COURSES_SAMPLE_EXCEL: 'generateCourseUploadExcel',
    COURSE_BULK_UPLOAD: 'bulkCourseUpload',
    VALIDATE_COURSE_UPLOAD: 'validateCourseUpload',
    UPLOAD_REPORT: 'uploadMultipleReports',
    GET_STUDENT_CAREER_NOTES: 'getStudentCareerNotes',
    SAVE_STUDENT_CAREER_NOTES: 'saveStudentCareerNotes',
    GET_COUNTRY_STATUS: 'getCountryStatus',
    SAVE__UPDATE_INSTITUTE_COUNTRIES: 'saveOrUpdateInstituteCountries',
    CAREER_NOTE_DELETE: 'deleteStudentCareerNotes',
    CAREER_NOTE_UPDATE: 'updateStudentCareerNotes',
    GETALL_CAREER_NOTES: 'getAllCareerNotes'
    },
}
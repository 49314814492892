import { Injectable } from '@angular/core';
import { User } from '@app/core/models/user';
import { Role } from '@app/core/models/role';
import { CurrentUserService } from '@app/services/currentUser.service';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
public currentUser: any
public currentUserPermission: any
constructor(private currentUserService: CurrentUserService,) { }
private user: User;

    isAuthorized() {
      this.currentUser = this.currentUserService.getCurrentUserDetails();
      //console.log('this.currentUser',this.currentUser)
      if(!this.currentUser){ return false;}
        return !!this.currentUser.role;
    }

    hasRole(role: Role) {
      //console.log('this.currentUser.role === role',this.currentUser.role , role)
        return this.isAuthorized() && this.currentUser.role === role;
    }

    login(role: Role) {
      this.user = { role: role };
    }

    logout() {
      this.user = null;
    }

    getUserRole() {
      return this.currentUser.role;
    }
    isHavingPermission(currentPermission){
      this.currentUserPermission = this.currentUserService.getCurrentUserPermission();
      //console.log('this.currentUserPermission', this.currentUserPermission)
      const listPermission = this.currentUserPermission.split(",")
     
      return listPermission.some(r => this.hasPermission(r,currentPermission));
    }
    hasPermission(permission:any,current) {
  //    console.log('listPerpermission === currentmission.some', permission, current)
      return permission === current
    }
}

<div class=" reportVideos">
  <div class="row no-gutters reportVideos__videoSectionHeading">
    <div class="col-9 col-md-9">
      <span class="videossHeading reportVideos__rightHeading"> Videos </span>
    </div>
    <div class="col-12 col-sm-12 col-md-3">
      <span class="reportVideos__rightSubHeading"><a href="/resources">View More</a></span>
    </div>
  </div>
  
  <div class=""> 
    <ngb-carousel class="reportVideos__courosel">
      <ng-container *ngFor="let videos of mresults">
        <ng-template ngbSlide>
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12" *ngFor="let item of videos">
              <div class="videoBox">
                <div class="reportVideos__video">
                   <!-- <a [attr.href]="item.video_url" target="_blank"> -->
                    <iframe marginwidth="0" marginheight="0" class="videoImage__subSection" scrolling="no"
                      frameborder="0" [src]="item.safe_url"></iframe>
                      <!-- </a>  -->
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>
  </div>
</div>

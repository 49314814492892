import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoleDirectiveDirective } from './userRoleDirective.directive';
import { UserPermissionDirective } from './userPermission.directive';
import { BlockCopyPasteDirective } from './blockCopyPaste.directive';
import { DragDropDirective } from './drag-drop.directive';

@NgModule({
  imports: [CommonModule],
  exports: [UserRoleDirectiveDirective, UserPermissionDirective, BlockCopyPasteDirective, DragDropDirective],
  declarations: [UserRoleDirectiveDirective, DragDropDirective, UserPermissionDirective, BlockCopyPasteDirective],
})
export class DirectiveModule {}

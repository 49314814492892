import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { API_CONSTANATS } from '../shared/api.constants';

@Injectable({
  providedIn: 'root'
})
export class ExtractReportService {
  apiBaseUrl = environment.serverUrl;
  apiCmsUrl = environment.cmsUrl;
  constructor(private http: HttpClient) {}
  extractReportPDF(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_EXTRACT_REPORT}`, data);
  }
  getCountries(data): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/${API_CONSTANATS.ADMIN.GET_COUNTRIES}`, data);
  }
  getLoanQuanlification(data): Observable<any> {
    return this.http.get(`${this.apiCmsUrl}/${API_CONSTANATS.STUDENT.LOAN_QUANLIFICATION}`, data);
  }
  getCourseDuration(data): Observable<any> {
    return this.http.get(`${this.apiCmsUrl}/${API_CONSTANATS.STUDENT.COURSE_DURATION}`, data);
  }
  getLoanAmount(data): Observable<any> {
    return this.http.get(`${this.apiCmsUrl}/${API_CONSTANATS.STUDENT.LOAN_AMOUNT}`, data);
  }
  getModeofCourses(data): Observable<any> {
    return this.http.get(`${this.apiCmsUrl}/${API_CONSTANATS.STUDENT.COURSE_MODE}`, data);
  }
  sendEmail(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.SEND_FUNDS_EMAIL}`, data);
  }
}

export const ROUTER_CONSTANATS = {
  STUDENT: {
    LOGIN: 'login',
    LANDING_PAGE: '',
    SET_PASSWORD: 'setPassword',
    QUESTIONNAIRE: 'questionnaire',
    GET_STARTED: 'getStarted',
    HA_QUESTIONS: 'haQuestions',
    PAGE_NOT_FOUND: '**',
    DASHBOARD: 'dashboard',
    PAYMENT: 'payment/:id',
    BOOKS: 'books',
    YOUR_STRENGTHS: 'yourStrengths',
    TERMS_AND_CONDITIONS: 'termsAndConditions',
    YOUR_PERSONALITY: 'yourPersonality',
    YOUR_TRAITOS: 'yourTraitos',
    PROFILE: 'profile',
    VIDEOS: 'videos',
    DEMO: 'haQuestionsDemo',
    SHORTLISTED_CAREERS: 'shortlistedCareers',
    CAREER_OPTIONS: 'careerOptions',
    FORGOT_PASSWORD: 'forgotPassword',
    CAREER_OPTIONS_DETAILS: 'careerDetails',
    YOUR_COUNSELLOR: 'yourCounsellor',
    YOUR_NOTES: 'yourNotes',
    YOUR_STREAM: 'yourStream',
    CAREER_ENJOYMENT: 'careerEnjoyment/:id',
    CAREERS_LIST: 'careersList',
    CAREER_LIST_SHORTLISTED_OLD: 'careerListShortlisted_old',
    CAREER_LIST_SHORTLISTED_NEW: 'careerListShortlisted',
    COURSES_LIST_SHORTLISTED: 'coursesListShortListed',
    ABOUT_US: 'aboutUs',
    RESOURCES: 'resources',
    STUDENT_EDIT_PROFILE: 'editProfile/:id',
    COURSES: 'courses',
    ERROR: 'pagenotfound',
    CLEARFUTURZ_NOTAVAILABLE: 'notexists'
  },
  ADMIN: {
    LOGIN: 'login',
    MAIN: 'admin',
    INSTITUTE: 'institute',
    RESELLER: 'reseller',
    COUNTRY_ADMIN: 'countryAdmin',
    GLOBAL_ADMIN: 'globalAdmin',
    BRANCH_ADMIN: 'branchAdmin',
  },
};

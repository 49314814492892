import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../imageConstants';
@Component({
  selector: 'cf-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainFooterComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  showAboutSystem:boolean;
  constructor() { }

  ngOnInit(): void {
  }
  cancelPopup(event) {
    this.showAboutSystem = false;
  }

}

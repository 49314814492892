import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { API_CONSTANATS } from '../shared/api.constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  apiURL = environment.cmsUrl;
  apiBaseUrl = environment.serverUrl;

  coursesApiSubject = new Subject<any>();
  constructor(private http: HttpClient) { }

  getBooks(data): Observable<any> {
    return this.http.get(`${this.apiURL}/${API_CONSTANATS.STUDENT.CMS_API.CAREER_BOOKS}`, data);
  }
  getVideos(data): Observable<any> {
    return this.http.get(`${this.apiURL}/${API_CONSTANATS.STUDENT.CMS_API.CAREER_VIDEOS}`, data);
  }
  getCourses(data): Observable<any> {
    return this.http.get(`${this.apiURL}/${API_CONSTANATS.STUDENT.CMS_API.CAREER_COURSES}`, data);
  }
  getNews(data): Observable<any> {
    return this.http.get(`${this.apiURL}/${API_CONSTANATS.STUDENT.CMS_API.CAREER_NEWS}`, data);
  }
  getTools(data): Observable<any> {
    return this.http.get(`${this.apiURL}/${API_CONSTANATS.STUDENT.CMS_API.CAREER_TOOLS}`, data);
  }
  getCareerBooks(data): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.EACH_CAREER_BOOKS}/${data.careerDetailID}`, data);
  }
  getCareerVideos(data): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.EACH_CAREER_VIDEOS}/${data.careerDetailID}`, data);
  }
  getGoogleNews(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.GOOGLE_NEWS}`, data);
  }
  getBooksbyCareer(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.BOOKS_BY_CAREER}`, data);
  }
  getCoursesbyCareer(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.COURSES_BY_CAREER}`, data);
  }
  getVideosbyCareer(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.VIDEOS_BY_CAREER}`, data);
  }
  getToolsbyCareer(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.TOOLS_BY_CAREER}`, data);
  }
  getFilteredData(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/getCourseFilter`, data)
  }
  getListOfData(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/getcourses`, data)
  }
  getListedOfSearched(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/courseList`, data)
  }
  deleteCourse(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/deleteCourse`, data)
  }
  // showCourses
  showCourses(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/showCourses`, data)
  }
  getshowother(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/getshowother`, data)
  }
  getFavSortListed(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/addfavorite`, data)
  }
  getfavorite(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/getfavorite`, data)
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMAGE_CONSTANTS } from '../../../shared/imageConstants';

@Component({
  selector: 'cf-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSpinnerComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  constructor(private spinner: NgxSpinnerService) {}
  ngOnInit(): void {}
}

<div class="footer-text">
  <p>
    © 2020 All Rights for respective trademarks, copyrights, information, materials including without
    limitation, text, logos, graphics, service marks, and trademarks are protected under applicable copyrights,
    trademarks and other proprietary rights, including without limitation, international copyright laws,
    international treaty provisions and all other applicable national laws, are reserved
  </p>
  <p>
    by Equal Leadership & Assessment Private Limited or its affiliates and partners, or their affiliates, such
    as but not limited to Amazon Inc, Google Inc, Harrison Assessments, o.net, etc. © Clear Futurz is the trade
    name of Equal Leadership & Assessment Private Limited. Equal Leadership & Assessment Private Limited also
    refers to the ELA network and/or its affiliates and partners aligned to ELA products and services, where
    <b>each of these affiliate firms are legally separate and independent entities. See Terms of Use and Privacy
      Policy for more detailed information.
    </b>
  </p>
</div>
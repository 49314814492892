import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsService } from './../settings.service';
import { StorageService } from './../../../../services/storage.service';
import { Router, Resolve } from '@angular/router';
import { Role } from "@app/core/models/role";
@Component({
  selector: 'cf-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  authUser: any = this.storage.getItem('loggedInUserDetails');
  @Output() cancelOutput:EventEmitter<string>= new EventEmitter();
  ratingCount: number;
  feedback: any = [
    { id: 0, checked: false },
    { id: 1, checked: false },
    { id: 2, checked: false },
    { id: 3, checked: false },
    { id: 4, checked: false },
  ];
  feedbackForms = this.formBuilder.group({
    description: [''],
    feedbackType: [''],
  });
  public role = Role
  constructor(
    private https: SettingsService,
    private storage: StorageService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  get feedbackControl() {
    return this.feedbackForms.controls;
  }

  ngOnInit() {}
  selectRating(index) {
    this.ratingCount = index + 1;
    this.feedback.forEach((res, i) => {
      if (res.id <= index) {
        res.checked = true;
      } else {
        res.checked = false;
      }
    });
  }

  onSubmit() {
    // this.feedbackForm.value.rating=this.ratingCount;
    this.https.insertFeedback(this.feedbackForms.value).subscribe((res) => {
      this.toastr.success('', 'Thank you for sharing the valuable feedback');
     // this.router.navigateByUrl(`/admin/${this.role.Institute}/settings/account`);
     this.cancel();
    });
  }
 cancel(){
   this.cancelOutput.emit('cancel');
 }
}

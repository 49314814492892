import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTER_CONSTANATS } from './shared/routerUrl.constants';
import { AuthGuardService } from './services/authGuard.service';

const routes: Routes = [
  {
    path: ROUTER_CONSTANATS.STUDENT.LOGIN,
    loadChildren: () => import('./modules/student/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.LANDING_PAGE,
    loadChildren: () => import('./modules/student/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.QUESTIONNAIRE,
    loadChildren: () =>
      import('./modules/student/questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.GET_STARTED,
    loadChildren: () => import('./modules/student/get-started/get-started.module').then((m) => m.GetStartedModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.HA_QUESTIONS,
    loadChildren: () => import('./modules/student/ha-questions/ha-questions.module').then((m) => m.HaQuestionsModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.DASHBOARD,
    loadChildren: () =>
      import('./modules/student/student-dashboard/student-dashboard.module').then((m) => m.StudentDashboardModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.PAYMENT,
    loadChildren: () =>
      import('./modules/student/payment-response/payment-response.module').then((m) => m.PaymentResponseModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.YOUR_STRENGTHS,
    loadChildren: () =>
      import('./modules/student/your-strengths/your-strengths.module').then((m) => m.YourStrengthsModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.TERMS_AND_CONDITIONS,
    loadChildren: () =>
      import('./modules/student/terms-and-conditions/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsModule
      ),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.YOUR_PERSONALITY,
    loadChildren: () =>
      import('./modules/student/your-personality/your-personality.module').then((m) => m.YourPersonalityModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.YOUR_TRAITOS,
    loadChildren: () => import('./modules/student/your-traitos/your-traitos.module').then((m) => m.YourTraitosModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.PROFILE,
    loadChildren: () => import('./modules/student/profile/profile.module').then((m) => m.ProfileModule),
  },

  {
    path: ROUTER_CONSTANATS.STUDENT.SHORTLISTED_CAREERS,
    loadChildren: () =>
      import('./modules/student/shortlisted-careers/shortlisted-careers.module').then(
        (m) => m.ShortlistedCareersModule
      ),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.CAREER_OPTIONS,
    loadChildren: () =>
      import('./modules/student/career-options/career-options.module').then((m) => m.CareerOptionsModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.YOUR_COUNSELLOR,
    loadChildren: () =>
      import('./modules/student/your-counsellor/your-counsellor.module').then((m) => m.YourCounsellorModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.YOUR_NOTES,
    loadChildren: () => import('./modules/student/your-notes/your-notes.module').then((m) => m.YourNotesModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.YOUR_STREAM,
    loadChildren: () => import('./modules/student/your-stream/your-stream.module').then((m) => m.YourStreamModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.CAREER_ENJOYMENT,
    loadChildren: () =>
      import('./modules/student/career-enjoyment/career-enjoyment.module').then((m) => m.CareerEnjoymentModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.CAREERS_LIST,
    loadChildren: () => import('./modules/student/careers-list/careers-list.module').then((m) => m.CareersListModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.CAREER_OPTIONS_DETAILS,
    loadChildren: () =>
      import('./modules/student/career-details/career-details.module').then((m) => m.CareerDetailsModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.ABOUT_US,
    loadChildren: () => import('./modules/student/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.CAREER_LIST_SHORTLISTED_OLD,
    loadChildren: () =>
      import('./modules/student/career-list-shortlisted/career-list-shortlisted.module').then(
        (m) => m.CareerListShortlistedModule
      ),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.COURSES_LIST_SHORTLISTED,
    loadChildren: () =>
      import('./modules/student/course-list-sort-listed/course-list-sort-listed.module').then(
        (m) => m.CourseListSortListedModule
      ),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.COURSES_LIST_SHORTLISTED,
    loadChildren: () =>
      import('./modules/student/career-list-shortlisted/career-list-shortlisted.module').then(
        (m) => m.CareerListShortlistedModule
      ),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.RESOURCES,
    loadChildren: () => import('./modules/student/resources/resources.module').then((m) => m.ResourcesModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.STUDENT_EDIT_PROFILE,
    loadChildren: () => import('./modules/student/edit-profile/edit-profile.module').then((m) => m.EditProfileModule),
  },
  {
    path: ROUTER_CONSTANATS.ADMIN.MAIN,
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: ROUTER_CONSTANATS.STUDENT.COURSES,
    loadChildren: () =>
      import('./modules/student/student-cources/student-cources.module').then((m) => m.StudentCourcesModule),
  },
  { path: ROUTER_CONSTANATS.STUDENT.COURSES, loadChildren: () => import('./modules/student/student-cources/student-cources.module').then(m => m.StudentCourcesModule) },
  { path: ROUTER_CONSTANATS.STUDENT.CLEARFUTURZ_NOTAVAILABLE, loadChildren: () => import('./modules/student/clearfuturz-notavailable/clearfuturz-notavailable.module').then(m => m.ClearfuturzNotavailableModule) }, 
  {
    path: ROUTER_CONSTANATS.STUDENT.CAREER_LIST_SHORTLISTED_NEW,
    loadChildren: () =>
      import('./modules/student/career-list-shortlisted-new/career-list-shortlisted-new.module').then(
        (m) => m.CareerListShortlistedNewModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/student/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CONSTANATS } from '../shared/api.constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsKnowmoreService {
  apiBaseUrl = environment.serverUrl;

  constructor(private http: HttpClient) {}
  getReportsKnowmore(data): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_REPORTS_KNOWMORE}`, data);
  }
}

<div class="shortlistedCareers">
  <div class=" col-md-12 shortlistedCareers__main">
    <div class=" header">
      <cf-color-logo></cf-color-logo>
      <cf-reports-drop-down></cf-reports-drop-down>
      <cf-reports-profile></cf-reports-profile>
    </div>

    <div class=" shortlistedCareers__main__career mt-4">
      <div class="row">
        <div class="col-sm-3">
          <h3 class="" routerLink="#">Career & Category</h3>
        </div>
        <div class="col-sm-3">
          <h3><img src="{{ IMAGES.SORT }}" />Req. Qualification</h3>
        </div>
        <div class="col-sm-3">
          <h3>Avg. Salary <span class="shortlistedCareers__main__career__title">(p.a)</span></h3>
        </div>
        <div class="col-sm-3">
          <h3>Your Career Suitability %</h3>
        </div>
      </div>
      <hr />
      <ng-container *ngFor="let item of shortListCareers;index as i;">
        <div class="row shortlistedCareers__main__career__record">
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-1 pt-1">
                <img src="{{ IMAGES.RATING_ACTIVE }}" />
              </div>
              <div class="col-sm-1">
                <img src="{{ IMAGES.PROFILE_PIC2 }}" />
              </div>
              <div class="col-sm-9">
                <h4 class="shortlistedCareers__main__career__record__name" routerLink="#">{{item.heading}}</h4>
                <p class="pb-2">{{item.subText}}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <p>{{item.qualification}}</p>
          </div>
          <div class="col-sm-2">
            <h4>66k</h4>
          </div>
          <div class="col-sm-3">
            <div class="row">
              <div class="col-md-10">
                <div class="progress">
                  <div [ngClass]="'progress-bar '+ item.progressBackground"></div>
                </div>
              </div>
              <div class="col-md-2 pl-1">
                <h3>{{item.careerSutability}}</h3>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>

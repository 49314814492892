import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { IMAGE_CONSTANTS } from '../../../shared/imageConstants';
import { ROUTER_CONSTANATS } from '../../../shared/routerUrl.constants';
import { StorageService } from '../../../services/storage.service';
import { ExtractReportService } from '../../../services/extract-report.service';
import { CreateShortlistService } from '../../../services/create-shortlist.service';
import Utils from '../../../shared/utils/utils';
import { QualificationsService } from '../../../services/qualifications.service';
import { CategoriesService } from '../../../services/categories.service';
import { Career } from '../../../core/models/career';
import { API_CONSTANATS } from '@app/shared/api.constants';
import { StudentService } from '@app/modules/admin/student/student.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { AnyCnameRecord } from 'dns';
@Component({
  selector: 'cf-career-options',
  templateUrl: './career-options.component.html',
  styleUrls: ['./career-options.component.scss'],
})
export class CareerOptionsComponent implements OnInit {
  @ViewChild('formDirective') private formDirective: NgForm;

  IMAGES: any = IMAGE_CONSTANTS;
  public searchFilter: string;
  public searchValue: string;
  public noteForm: FormGroup;
  public notesList: any;
  public editId: string;
  apiCmsUrl = environment.cmsUrl;
  public submitButton: boolean;
  public submitted: boolean;
  public myData: any;
  public showShare: boolean;
  public crossMark: boolean = false;
  constructor(
    private careerService: ExtractReportService,
    private router: Router,
    private storage: StorageService,
    private shortlist: CreateShortlistService,
    private spinner: NgxSpinnerService,
    private qualification: QualificationsService,
    private category: CategoriesService,
    private _eref: ElementRef,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private toastr: ToastrService
  ) {}
  page = 1;
  pageSize = 50;
  collectionSize: any;
  careers: Career[];
  careersList: Career[];
  toogleSalary = false;

  // To handle header and right section search.
  listOfCareersBackup = [];
  categorylistbackup = [];

  // To handle search things.
  searchOptions: any = [];

  // To handle right section search.
  searchCareerOptions: any = [];
  categorylistbackupNew = [];
  qualificationLineNew = [];
  qualificationsListString: any;
  selectedCareer: any;
  selectedObject: any = '';
  selectedQualifications = [];
  selectedTopCategory = 5;

  // To handle percentage related grades.
  grades = Utils.grades;

  // To handle filter,share,notes on right section.
  filterSection: any = true;
  notesSection: any = false;
  shareSection: any = false;
  feedbackSection: any = false;

  // To handle top careerslist
  topCareersList: any = [
    {
      id: 1,
      textName: 'Top (10)',
      isSelected: false,
      topCareersCount: 20,
    },
    {
      id: 2,
      textName: 'Top (25)',
      isSelected: false,
      topCareersCount: 25,
    },
    {
      id: 3,
      textName: 'Top (50)',
      isSelected: false,
      topCareersCount: 50,
    },
    {
      id: 4,
      textName: 'Bright Careers',
      isSelected: false,
      topCareersCount: 'BrightCareers',
    },
    {
      id: 5,
      textName: 'Select All',
      isSelected: false,
      topCareersCount: 'SelectAll',
    },
  ];

  ngOnInit(): void {
    this.myData = this.storage.getItem('userData');
    this.qualification.getQualifications('').subscribe((res: any) => {
      // TODO: need to add status from the backend side and then need to update at ui side.
      this.qualificationLineNew = res;
    });

    this.category.getCareers('').subscribe((res: any) => {
      const sortedusers = res.sort(function (a, b) {
        var nameA = a.categoryName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.categoryName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names must be equal
      });
      this.categorylistbackupNew = sortedusers;

      console.log('>>>>>>>', this.categorylistbackupNew);
    });
    this.getData();
    this.topCareersListSearch(5);
    // if(this.storage.getItem('backCategory')){
    //   this.categorylistbackupNew.forEach(res=>{
    //     if(res.categoryName === this.storage.getItem('backCategory')[0].categoryName){
    //       res.IsDefaultSelected= !res.IsDefaultSelected;
    //     }
    //   });
    // }
    this.setFormFields();
    this.fetchFilter();
    this.refreshAds();
  }

  ngAfterViewInit() {
    this.pushAfterRefresh();
    this.refreshAds();
  }

  pushAfterRefresh() {
    setTimeout(() => {
      if (typeof window !== 'undefined' && window['adsbygoogle']) {
        try {
          (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
          console.log("window['adsbygoogle'] ", window['adsbygoogle']);
        } catch (e) {
          console.error('AdSense error:', e);
        }
      } else {
        console.warn('AdSense script not loaded.');
      }
    }, 900);
  }

  refreshAds(): void {
    const ads = document.querySelectorAll('ins.adsbygoogle');
    ads.forEach((ad) => {
      if (ad || ad.getAttribute('adsbygoogle')) {
        console.log('ad', ad);
        ad.removeAttribute('adsbygoogle');
      }
    });
  }

  onPageChange() {
    this.refreshAds();
    this.pushAfterRefresh();
  }
  get noteRegistration(): any {
    return this.noteForm.controls;
  }
  public setFormFields(): any {
    this.noteForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      details: ['', [Validators.required]],
    });
  }
  getData(): any {
    setTimeout(() => {
      this.spinner.show();
    }, 10);

    const myData = this.storage.getItem('userData');
    const data = { student_id: myData.student_id, reportName: 'Co1' };
    this.careerService.extractReportPDF(data).subscribe((res: any) => {
      if (res.status === 1) {
        this.careersList = res.data.report_data;
        this.careers = this.careersList;
        let catFilter = res.data.report_data.map((res) => res.categoryName);

        let arr1 = [...new Set(catFilter)];
        // console.log('>>>>>', arr1);
        const filterByReference = (arr1, arr2) => {
          let res = [];
          res = arr2.filter((el) => {
            return arr1.find((element) => {
              return element === el.categoryName;
            });
          });
          return res;
        };
        let vocational = this.categorylistbackupNew[this.categorylistbackupNew.length - 1];
        console.log('>>>>>', vocational);
        this.categorylistbackupNew = filterByReference(arr1, this.categorylistbackupNew);
        this.categorylistbackupNew.push(vocational);
        // console.log('',filterByReference(arr1,this.categorylistbackupNew));
        this.collectionSize = res.data.report_data.length;
        //this.refreshCountries();
        this.spinner.hide();
      }
    });
  }
  refreshCountries(): any {
    this.careers = this.careersList
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  /* To sort careers based on scores he got id different careers */
  sortByScore(): any {
    const arrangeItems = this.careers;
    let newOrderList: any;
    if (this.toogleSalary) {
      this.toogleSalary = false;
      newOrderList = arrangeItems.sort((a, b) => a.score - b.score);
      this.careers = newOrderList;
    } else {
      this.toogleSalary = true;
      newOrderList = arrangeItems.sort((a, b) => b.score - a.score);
      this.careers = newOrderList;
    }
    // this.paginateValues();
  }

  /* This method is for navigating to a selected career page from the UI*/
  goToCareerDetails(careerID, score): any {
    const finalLink = '/' + ROUTER_CONSTANATS.STUDENT.CAREER_OPTIONS_DETAILS + '?id=' + careerID + '&score=' + score;
    this.router.navigateByUrl(finalLink);
  }

  /* This method is to make career as a shortlisted career for the respective user*/
  shortListingCareer(id, isShortlisted): any {
    const myData = this.storage.getItem('userData');
    if (isShortlisted === 'true') {
      isShortlisted = 'false';
    } else {
      isShortlisted = 'true';
    }
    const data = { studentId: myData.student_id, shortlistType: isShortlisted, haId: id };
    this.shortlist.addCareerToShortlisted(data).subscribe((res: any) => {
      if (res.status === 1) {
        this.getData();
      }
    });
  }

  /* Below 4 functions are ment to handle padding in tabs, and displaying respective text */
  filter(): any {
    this.filterSection = true;
    this.notesSection = false;
    this.shareSection = false;
    this.feedbackSection = false;
  }
  notes(): any {
    this.filterSection = false;
    this.notesSection = true;
    this.shareSection = false;
    this.feedbackSection = false;
  }
  share(): any {
    this.filterSection = false;
    this.notesSection = false;
    this.shareSection = true;
    this.feedbackSection = false;
  }
  feedback(): any {
    this.filterSection = false;
    this.notesSection = false;
    this.shareSection = false;
    this.feedbackSection = true;
  }

  /*  Displaying List of careers based on education level.(i.e from lower study to higher study) */
  sortByEducation(): any {
    // TODO: need to add this functionality.
  }

  /* Header related careers filtering code begin */
  selectedIndex = 0;

  onKeyup(evt: any): any {
    console.log(evt);
    //const searchTerm = evt.target.value.trim().toLowerCase();
    this.crossMark = true;
    let searchTerm = '';
    if (evt.target.value !== '' && evt.target.value !== null && evt.target.value !== undefined) {
      searchTerm = evt.target.value.trim().toLowerCase();
    } else {
      searchTerm = '';
    }
    if (searchTerm.length) {
      if (evt.keyCode != 38 && evt.keyCode != 40) {
        this.searchOptions = [
          ...this.categorylistbackup.filter((cat) => cat.categoryName.toLowerCase().indexOf(searchTerm) !== -1),
          ...this.careersList.filter((cat) => cat.description.toLowerCase().indexOf(searchTerm) !== -1),
        ];
        console.log(this.searchOptions);
        this.selectedIndex = -1;
      }
    } else {
      this.crossMark = false;
      this.searchOptions = [];
      this.isSearchDataEmpty();
      this.careers = this.careersList;
      this.selectedIndex = 0;
    }
    if (evt.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex -= 1;
        this.scrolldiv();
        this.searchValue = this.searchOptions[this.selectedIndex].description
          ? this.searchOptions[this.selectedIndex].description
          : this.searchOptions[this.selectedIndex].categoryName;
        // console.log(scrollTop)
      }
    }
    if (evt.keyCode == 40) {
      if (this.selectedIndex < this.searchOptions.length) {
        this.selectedIndex += 1;
        this.scrolldiv();
        this.searchValue = this.searchOptions[this.selectedIndex].description
          ? this.searchOptions[this.selectedIndex].description
          : this.searchOptions[this.selectedIndex].categoryName;
        // console.log(scrollTop)
      }
    }
    if (evt.keyCode == 8) {
      this.selectedIndex = 0;
    }
    if (evt.keyCode == 13) {
      this.selectedIndex = 0;
      this.onSearchEnter(evt.target.value);
      // this.onSelectFilter(this.searchOptions[this.selectedIndex]);
    }

    console.log(this.selectedIndex);
  }

  scrolldiv() {
    let el = 'headerSearchOp' + this.selectedIndex;
    let scrolltop = document.getElementById(el).getBoundingClientRect();
    let overlow = document.getElementById('search-overflow');
    // console.log(document.getElementById(el).scrollHeight, document.getElementById(el).clientHeight, "////////", document.getElementById('search-overflow').scrollTop, document.getElementById('search-overflow').scrollHeight, document.getElementById('search-overflow').clientHeight, scrolltop)
    // document.getElementById(el).scrollTop = 0;
    console.log(
      scrolltop.top,
      document.getElementById('search-overflow').clientHeight,
      scrolltop.top > document.getElementById('search-overflow').clientHeight,
      'scrolldata',
      document.getElementById(el).offsetTop
    );
    if (scrolltop.top - 10 > document.getElementById('search-overflow').clientHeight) {
      overlow.scrollTo({ top: document.getElementById(el).offsetTop, behavior: 'smooth' });
    }
    // overlow.scrollTo(0, overlow.offsetTop); //scrollTop = scrolltop.top //({ top: scrollTop.top + 50, left: 0, behavior: 'smooth' })
  }
  onSelectFilter(filter: any): any {
    this.searchValue = filter.description;
    this.careers = filter.description
      ? [...this.careersList.filter((cat) => cat.description === filter.description)]
      : [...this.careersList.filter((cat) => cat.categoryName === filter.categoryName)];
    this.collectionSize = this.careers.length;
    this.careers = this.careers
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    this.searchOptions = [];
  }
  getStyle(option): any {
    return { background: option.color || '#' + Math.floor(Math.random() * 16777215).toString(16) };
  }
  /* Header related careers filtering code BEGIN */

  /**************************Multi fields based filtering section begin ***************/

  /* It gives selected selected Career on right section*/
  onKeyupCareer(evt: any): any {
    const searchTerm = evt.target.value.trim().toLowerCase();
    if (searchTerm.length) {
      this.searchCareerOptions = [
        ...this.careersList.filter((cat) => cat.description.toLowerCase().indexOf(searchTerm) !== -1),
      ];
    } else {
      this.searchCareerOptions = [];
      this.selectedObject = {};
      this.selectedCareer = '';
    }
  }
  onCareerSelectFilter(filter: any): any {
    this.selectedObject = filter;
    this.selectedCareer = filter.description;
    this.careers = [...this.careersList.filter((cat) => cat.description === filter.description)];
    this.searchCareerOptions = [];
    this.handleMultiSearch('careerType');
  }

  // It gives selected qualification's on right section filter
  qualificationBasedSearchNew(): any {
    setTimeout(() => {
      // Preparing String to display UI side and getting selected checkboxes related qualifications
      let listString = '';
      console.log('>>this.qualificationLineNew', this.qualificationLineNew);
      const qualificationsNew = [...this.qualificationLineNew.filter((study) => study.IsDefaultSelected)];
      qualificationsNew.forEach((item) => {
        listString += item.qualificationName + ' , ';
      });
      this.selectedQualifications = qualificationsNew;
      this.qualificationsListString = listString;

      // const filterByReference = (arr1, arr2) => {
      //   let res = [];
      //   res = arr2.filter((el) => {
      //     return arr1.find((element) => {
      //       return element.qualificationName === el.qualificationName;
      //     });
      //   });
      //   return res;
      // };
      // this.careers = filterByReference(this.selectedQualifications,this.careers);
      this.handleMultiSearch('education');
    }, 2);
  }

  // It gives selected list like, Top20,Top30...etc
  topCareersListSearch(data): any {
    this.selectedTopCategory = data;
    this.topCareersList.forEach((item: any) => {
      if (data === item.id) {
        if (item.isSelected) {
          item.isSelected = false;
        } else {
          item.isSelected = true;
        }
      } else {
        item.isSelected = false;
      }
    });
    this.handleMultiSearch('listType');
  }

  // It gives selected education types on right section filter
  categoryBasedSearch(event): any {
    setTimeout(() => {
      const categoriesNew2 = [...this.categorylistbackupNew.filter((cat) => cat.IsDefaultSelected)];
      console.log('>>>', categoriesNew2);
      this.storage.setItem('backCategory', categoriesNew2);
      this.handleMultiSearch('category');
    }, 0);
  }

  isSearchDataEmpty(): any {
    this.collectionSize = this.careersList.length;
    //this.refreshCountries();
  }

  clearSearch(): any {
    this.selectedObject = {};
    this.selectedCareer = '';
    this.searchCareerOptions = [];
    this.handleMultiSearch('careerType');
  }
  /* Multi fields based filtering section END */

  /*Common functions for the Advanced search functionality */
  advancedSelectedCareer(): any {
    // Setting all list of careers
    if (this.selectedObject?.description) {
      this.careers = [...this.careersList.filter((cat) => cat.description === this.selectedObject.description)];
      console.log('>>advancedSelectedCareer>>>');
    } else {
      this.careers = this.careersList;
      console.log('>>advancedSelectedCareer> else>>');
    }
  }
  advancedQualificationList(): any {
    setTimeout(() => {
      const qualificationsSelected = [...this.qualificationLineNew.filter((study) => study.IsDefaultSelected)];
      if (qualificationsSelected.length > 0) {
        const careersNew = [];
        qualificationsSelected.forEach((quaitem) => {
          console.log(quaitem, this.careers);
          this.careers.forEach((career) => {
            console.log(career);
            if (quaitem.id === career.qualificationId) {
              careersNew.push(career);
            }
          });
        });

        this.careers = careersNew;
        console.log('>>this.careers else>>', this.careers);
      }
    }, 10);
    // Get the selected education related check box values.
  }
  advancedList(): any {
    // Get top selected items
    const topList = [...this.topCareersList.filter((list: any) => list.isSelected)];
    if (topList.length > 0) {
      // const arrangeItems = this.careers;
      const newOrderList = Array.isArray(this.careers) ? this.careers : [];
      let countValue = 0;
      if (this.selectedTopCategory === 1) {
        countValue = 10;
      } else if (this.selectedTopCategory === 2) {
        countValue = 25;
      } else if (this.selectedTopCategory === 3) {
        countValue = 50;
        // } else if (this.selectedTopCategory === 4) {
      } else if (this.selectedTopCategory === 5) {
        countValue = this.careers?.length;
      }
      let filterList = [];
      if (countValue > 0) {
        for (let i = 0; i < countValue; i++) {
          filterList.push(newOrderList[i]);

          // When career is selected in search, there will be one record. Empty values are coming if not used below condition
          if (this.selectedObject?.description) {
            break;
          }
        }
        this.careers = filterList;
        console.log('>>filterList>>', filterList);
      } else {
        if (this.careers) {
          filterList = [...this.careers?.filter((career: any) => career?.IsBrightCareer === 'true')];
          this.careers = filterList;
          console.log('>>else>>', filterList);
        }
      }
    }
  }
  advancedCategoriesList(): any {
    // Get selected categories related values
    const selectedCategories = [...this.categorylistbackupNew.filter((category) => category.IsDefaultSelected)];
    // console.log('>>>>',selectedCategories)
    if (selectedCategories.length > 0) {
      const searchCareers = [];
      selectedCategories.forEach((items) => {
        this.careers.forEach((career) => {
          console.log('------', career.categoryId, '===', items.categoryId, items.categoryName);
          if (career.categoryId === items.categoryId) {
            console.log('------', career);
            searchCareers.push(career);
          }
          if (items.categoryName === 'Vocational') {
            if (career['Is_Vocational'] === 'true') {
              console.log('>>>>true', career);
              searchCareers.push(career);
              let dataCheck = !searchCareers.find((o) => o.categoryId === career.categoryId);
              console.log('>>>>>>chhh', dataCheck);
              // }
            }
            // if (!searchCareers.find(o => (o.categoryId === career.categoryId) && career['Is_Vocational'] === 'true')){
            // searchCareers.push(career);
            // }
          }
        });
      });

      searchCareers.sort(function (a, b) {
        return b.score - a.score;
      });
      // this.careers = new Set[...searchCareers];
      this.careers = searchCareers.filter(
        (resp, index, self) => index == self.findIndex((t) => t.detailsid == resp.detailsid)
      );

      console.log('>>searchCareers>>', searchCareers);
    }
  }

  paginateValues(): any {
    // this has to be executed lastly
    this.careers = this.careers
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

    console.log(this.careers);
    this.collectionSize = this.careers.length;
  }

  handleMultiSearch(type): any {
    this.advancedSelectedCareer();
    setTimeout(() => {
      this.advancedQualificationList();
    }, 10);
    setTimeout(() => {
      this.advancedList();
    }, 20);
    setTimeout(() => {
      this.advancedCategoriesList();
    }, 30);
    // setTimeout(() => {
    //   this.paginateValues();
    // }, 40);
  }

  public onSubmit(): any {
    this.submitButton = true;
    if (this.noteForm.invalid) {
      this.submitButton = false;
      this.submitted = true;
      return;
    }
    this.addNotes();
  }

  public addNotes() {
    let params, url;
    if (this.editId) {
      params = {
        note: this.noteRegistration.details.value,
        Id: this.editId,
        title: this.noteRegistration.title.value,
      };
      url = API_CONSTANATS.ADMIN.CANDIDATE_NOTE_EDIT;
    } else {
      params = {
        note: this.noteRegistration.details.value,
        studentId: this.myData.student_id,
        title: this.noteRegistration.title.value,
      };
      url = API_CONSTANATS.ADMIN.CANDIDATE_NOTE_ADD;
    }

    //this.spinner.show('citySpinner');
    this.studentService.getPostData(url, params).subscribe((sucess) => {
      this.fetchFilter();
      this.toastr.success('Success!', 'Notes added successfully');
      if (this.editId) {
        this.editId = undefined;
      }
      this.formDirective.resetForm();
    });
  }

  public editNotes(notesDetails) {
    this.noteForm.controls['title'].setValue(notesDetails.title);
    this.noteForm.controls['details'].setValue(notesDetails.note);

    this.editId = notesDetails.id;
  }

  public deleteNote(id) {
    this.spinner.show('citySpinner');
    this.studentService.getPostData(API_CONSTANATS.ADMIN.CANDIDATE_NOTE_DELETE, { Id: id }).subscribe((sucess) => {
      this.fetchFilter();
      //this.spinner.hide('citySpinner');
      this.toastr.success('Success!', 'Notes deleted successfully');
    });
  }

  cancelPopup(event) {
    this.showShare = false;
  }

  private fetchFilter() {
    // this.spinner.show('citySpinner');
    this.studentService
      .getPostData(API_CONSTANATS.ADMIN.CANDIDATE_NOTE_LIST, { studentId: this.myData.student_id })
      .subscribe((sucess) => {
        this.notesList = sucess.result;
      });
  }
  onSearchEnter(filter): any {
    this.searchValue = filter;
    this.careers = [];
    this.searchOptions.filter((element) => {
      this.careers.push(element);
    });
    this.collectionSize = this.careers.length;
    this.searchOptions = [];
  }
}

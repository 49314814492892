import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../imageConstants';
import { ROUTER_CONSTANATS } from '../../../shared/routerUrl.constants';
import { StorageService } from '@app/services/storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'cf-color-logo',
  templateUrl: './color-logo.component.html',
  styleUrls: ['./color-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorLogoComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  dashBoard = ROUTER_CONSTANATS.STUDENT.DASHBOARD;
  login = ROUTER_CONSTANATS.STUDENT.LOGIN;
  constructor(private storage: StorageService, private router: Router
  ) { }
  ngOnInit(): void {
  }
  goTohome() {
    if (this.router.url === '/getStarted') {
      this.storage.clear();
      this.router.navigateByUrl(ROUTER_CONSTANATS.STUDENT.LOGIN);
    } else {
      this.router.navigateByUrl(ROUTER_CONSTANATS.STUDENT.DASHBOARD);
    }
  }

}

<div class="searchContainer">
  <img src="../../../../assets/icons/searchIcon.png">
  <input type="text" class="form-control search " (keyup)="onKeyup($event)" />
  <div *ngIf="searchOptions.length > 0" class="search-options">
    <div *ngFor="let option of searchOptions" (click)="onSelectFilter(option)">
      <span class="icon"
        [style]="getStyle(option)">{{(option.description || option.categoryName).substring(0,1)}}</span>
      <span>{{option.description || option.categoryName}}</span>
    </div>
  </div>
</div>

export const IMAGE_CONSTANTS = {
  WHITE_LOGO: 'assets/images/logos/white_logo.svg',
  QUOTE_PIC: 'assets/images/others/LoginStudent.png',
  QUOTE_PIC1: 'assets/images/others/ForgotpwdStudent.png',
  LOGO: 'assets/H-logo.svg',
  LOGO_BLACK: 'assets/images/logos/logo.svg',
  CF_LOGO: 'assets/cf_logo.svg',
  BACK: 'assets/back.svg',
  BOOKS_ICON: 'assets/education.svg',
  PHYSICAL_ICON: 'assets/icons/physicalbook_selected.svg',
  EBOOK_ICON: 'assets/icons/ebook_normal.svg',
  COUNSELLER3: 'assets/counsellor3.png',
  DOWN_ARROW: 'assets/down-arrow.svg',
  EBOOK_SELECTED: 'assets/ebook selected.svg',
  EDUCATION: 'assets/education.svg',
  GETSTARTED: 'assets/getstarted.png',
  GROUP_2899: 'assets/GROUP_2899.png',
  ERROR_404: 'assets/images/404error.png',
  ONE: 'assets/images/Avatars/male/1.svg',
  LOGO2: 'assets/images/Images/logo.svg',
  LOGINSINGLE: 'assets/images/others/LoginSingle.jpg',
  LOGO3: 'assets/images/others/logo.svg',
  NEXT: 'assets/images/others/next.svg',
  INDIAN_RUPEE: 'assets/Indian-Rupee-symbol.svg',
  INFO: 'assets/info.svg',
  NOTE_SELECTED: 'assets/note-selected.svg',
  PERSONALITY: 'assets/personality.png',
  PERSONALITY_ONE: 'assets/personalityreport/1.svg',
  PERSONALITY_TWO: 'assets/personalityreport/2.svg',
  PERSONALITY_THREE: 'assets/personalityreport/3.svg',
  PERSONALITY_FOUR: 'assets/personalityreport/4.svg',
  PHYSICALBOOK_NORMAL: 'assets/physicalbook_normal.svg',
  PLAY: 'assets/play.svg',
  PROFILE_PIC: 'assets/profile_pic.png',
  PROFILE_PIC2: 'assets/peoplealsolike_1.png',
  SHARE: 'assets/share.svg',
  SUCCESS: 'assets/success.jpg',
  YGS_AORG: 'assets/ygs-aorg.svg',
  YGS_APINK: 'assets/ygs-apink.svg',
  INFINITY_SYMBOL: 'assets/ygs-asset1.svg',
  CURVED_YELLOW_PIC: 'assets/ygs-asset2.svg',
  YGS_AYELLOW: 'assets/ygs-ayellow.svg',
  STRENGTHS_MAIN_PIC: 'assets/ygs-img1.png',
  NOT_EXISTS_BOTTOM: 'assets/bottom-icon.svg',

  INFINITY_TWO: 'assets/l-asset1.svg',
  LB_ONE: 'assets/lb-1.png',
  VIOLET_CURVE: 'assets/l-asset2.svg',
  WHITE_CLOUDES: 'assets/l-asset14.svg',
  PLAIN_CLOUDES: 'assets/l-asset15.svg',
  WHITE_CLOUDE_TWO: 'assets/l-asset16.svg',
  SUCCESS_GROUP: 'assets/Success-group.png',
  SUCCESS_YELLOW_GROUP: 'assets/Success-group1.svg',
  CAREERPLATFORM: 'assets/CFCP-careerplatform.svg',
  CAREERENCYCLOPEDIA: 'assets/CFCP-careerencyclopedia.svg',
  CAREERDASHBOARD: 'assets/CFCP-careerdashboard.svg',
  YOUR_GREATEST_STRENGTH: 'assets/CFCP-yourgreateststrength.svg',
  DISCOVER: 'assets/CFCP-discover.svg',
  CLOUD: 'assets/l-asset4.svg',
  PRICING: 'assets/pricing-1.svg',
  BLUE_CLOUDES: 'assets/l-asset5.svg',
  PRICING2: 'assets/pricing-2.svg',
  COLORED_STARS: 'assets/l-asset6.svg',
  PRICING_CHECKBOX: 'assets/pricing-checkbox.svg',
  YELLOW_CLOUDES: 'assets/l-asset7.svg',
  DOT_ON_CIRCLE: 'assets/l-asset8.svg',
  GREEN_DOT_ON_CIRCLE: 'assets/l-asset9.svg',
  GTONE: 'assets/GT-1.png',
  GTTWO: 'assets/GT-2.png',
  YELLOW_HALF_CIRCLE: 'assets/l-asset10.svg',
  GTTHREE: 'assets/GT-3.png',
  FE_FOUR: 'assets/FE-4.png',
  FE_THREE: 'assets/FE-3.png',
  FE_TWO: 'assets/FE-2.png',
  FE_ONE: 'assets/FE-1.png',
  HALF_CIRCLE_LEFT: 'assets/l-asset11.svg',
  DENTIST: 'assets/personality-dentist.png',
  CHEF: 'assets/personality-chef.png',
  ANALYST: 'assets/personality-analyst.png',
  FILM_DIRECTOR: 'assets/personality-filmdirector.png',
  SOFTWARE: 'assets/personality-software.png',
  APP_DESIGNER: 'assets/personality-appdesigner.png',
  WHITE_BACKGROUND: 'assets/l-asset3.svg',
  HALF_CIRCLE: 'assets/l-asset12.svg',
  TWO_LINES: 'assets/l-asset13.svg',
  CALL: 'assets/footer-call.png',
  FACEBOOK: 'assets/Footer-facebook.svg',
  INSTAGRAM: 'assets/Footer-instagram.svg',
  GOOGLE: 'assets/Footer-google.svg',
  TESTNOW: 'assets/lb-testnow.png',
  PLAY_TWO: 'assets/play2.svg',
  GET_STARTED: 'assets/lb-sbdemo.png',
  SORT: 'assets/icons/sort.svg',
  RATING: 'assets/icons/rating.svg',
  RATING_ACTIVE: 'assets/icons/rating_selected.svg',
  GET_STARTED2: 'assets/getStarted2.png',
  QUESTIONNAIRE_INTRO_BG: 'assets/Questionnaire_Intro_bg.svg',
  SUCCESS_TICK: 'assets/successTick.svg',
  FAILURE_TICK: 'assets/failureTick.svg',

  // careerOptionDetails
  ABILITIES: 'assets/icons/abilities.svg',
  ABROAD: 'assets/icons/abroad.svg',
  BACK1: 'assets/icons/left-arrow-white.png',
  BOOKS: 'assets/book.png',
  COURSE_IMG: 'assets/sharereport_dentist.jpg',
  COURSES: 'assets/icons/courses.svg',
  CROWD_SOURCE_NORMAL: 'assets/icons/crowd_source_normal.svg',
  FUNDING: 'assets/icons/funding.svg',
  INDIA_SELECTED: 'assets/icons/india_selected.svg',
  KNOWLEDGE: 'assets/icons/knowledge.svg',
  LEADING_INSTITUTE: 'assets/icons/leading_institutes.svg',
  LOAN_NORMAL: 'assets/icons/loan_normal.svg',
  SCHOLORSHIP_SELECTED: 'assets/icons/scholorship_selected.svg',
  SCORE1: 'assets/icons/score_1.png',
  SCORE2: 'assets/icons/score_2.png',
  SCORE3: 'assets/icons/score_3.png',
  STAR: 'assets/icons/star.png',
  VIDEOS: 'assets/icons/videos.svg',
  SOURCE_ADD_INFO: 'assets/icons/source_of_additional_info.svg',
  WORK_ACTIVITIES: 'assets/icons/work-activities.svg',

  ICON_1: 'assets/icons/note_notification.svg',
  ICON_2: 'assets/icons/note_share.svg',
  ICON_3: 'assets/icons/delete.svg',
  ICON_4: 'assets/icons/tick-box.svg',
  ICON_5: 'assets/icons/plus.svg',

  INFORMATION: 'assets/information.svg',
  TWO_CIRCLES: 'assets/bg_circles.svg',
  WHITE_STAR: 'assets/whitestar.svg',
  YELLOW_STAR: 'assets/yellowstar.svg',
  ARROW_DOWN: 'assets/arrowDown.svg',

  REPORT_BACK: 'assets/backArrowSmall.svg',
  WELCOME_SECTION: 'assets/welcomeSectionImage.png',

  SHOPPINT_CART: 'assets/careerList/shoppingCartIcon.svg',
  CART: 'assets/careerList/cartItemHeadingIcon.svg',
  YELLOW_CURVE: 'assets/careerList/hoveritemBackground.svg',
  CARD_PIC: 'assets/careerList/itemBackground.svg',
  BLACK_PLAY: 'assets/blackPlayButton.svg',
  MEETER_RED: 'assets/careerDetails/images/Tasks-2.svg',
  MEETER_YELLOW: 'assets/careerDetails/images/Tasks-1.svg',
  MEETER_GREEN: 'assets/careerDetails/images/Tasks-3.svg',
  BLACK_DOT: 'assets/blackDot.svg',
  LEFT_ARROW_WITH_TEST: 'assets/leftArrowAndText.svg',
  BLUE_CIRCLE: 'assets/careerDetails/images/Bluecircle.svg',
  YELLOW_CIRCLE: 'assets/careerDetails/images/yellowcircle.svg',
  SOURCES: 'assets/careerDetails/images/sources-icon.svg',
  ABILITIES_HEADER: 'assets/careerDetails/images/Abilities-Header-img.svg',
  BOOKSICON: 'assets/careerDetails/images/books-icon.svg',
  EBOOKICON: 'assets/careerDetails/images/Books-Physical.svg',
  TAB_EBOOK: 'assets/careerDetails/images/Books-Ebook.svg',
  CAREERS_ICON: 'assets/careerDetails/images/courses-icon.svg',
  INSTITUTE_ICON: 'assets/careerDetails/images/Leading-header-img.svg',
  INDIA_LOGO: 'assets/careerDetails/images/Leadings-India.svg',
  WORLD_LOGO: 'assets/careerDetails/images/Leading-Abroad .svg',
  NOTE: 'assets/careerDetails/images/addNote.svg',
  KNOWLEDGE_HEADER: 'assets/careerDetails/images/Knowledge-Header-img.svg',
  CIRCLES: 'assets/careerDetails/images/circles.svg',
  TASKS: 'assets/careerDetails/images/Tasks-Header-img.svg',
  VIDEO_PIC: 'assets/careerDetails/images/Videos-Header.svg',
  WORK_ACTIVITY: 'assets/careerDetails/images/WorkActivities-Header-img.svg',
  BLUE_LINE: 'assets/icons/blueLineIcon.svg',
  BOOK_ICON: 'assets/icons/bookIcon.svg',
  FEED_BACK: 'assets/icons/charIcon.svg',
  SHARE_ICON_GREEN: 'assets/icons/share2.svg',
  FILTER_ICON_BLUE: 'assets/icons/filter.svg',
  CANCEL_ICON: 'assets/note_cancel.svg',
  CUSTOM_LOADER: 'assets/infinityLogo.gif',
  BELL_ICON:  'assets/icons/Bell.svg',
  SHARE_ICON: 'assets/icons/bellShare.svg',
  CHECK_ICON: 'assets/icons/checkImage.svg',
  DELETE_ICON: 'assets/icons/delete.svg',
  MORE_ICON: 'assets/icons/moreOptionImage.svg',
  // Api related images. Which we can remove once api integration is done. because, under these related images will come from backend.
  DENTIST_IMAGE: 'assets/apiRelatedImages/dentistPic.svg',
  BOOK_IMAGE: 'assets/apiRelatedImages/COD-B-1.png',
  COURSE_BOOK: 'assets/careerDetails/images/Course-Book-1.png',

  // Admin images
  HEADER_ICON: 'assets/upload_bg.svg',
  PROFILE_ICON: 'assets/studentprofile.png',
  PROFILE_EDIT_ICON: 'assets/editprofileimage.png',
  DOWNLOAD_ARROW: 'assets/downArrow.png',
  UPLOAD_ICON: 'assets/Upload.png',
  LOADER: 'assets/Rolling-1s-200px.gif',
  SUCCESS_ICON: 'assets/ok.svg',
  EXCEL_ICON: 'assets/excel 1.svg',
  CLOSE_ICON: 'assets/close.png',
  PAGE_UNDER_CONSTRUCTION: 'assets/page-under-construction-1.jpg',
  SIGNIN_ICON: 'assets/icons/signin-icon.svg',
  FOOTER_ASSETS: 'assets/footer-asset.svg',
  FORGET_ICON: 'assets/forgot-icon.svg',
  PASSWORD_RESET_ICON: 'assets/reset-icon.svg',
  RESET_ICON: 'assets/success-icon.svg',
  STATUS_RESET_ICON: 'assets/statusImage.svg',
  STUDENT_MORE_ICON: 'assets/moreOptions.svg',
  SORT_ICON: 'assets/sortingArrow.svg',
  BACK_BUTTON: 'assets/backbuttonImage.svg',
  BACK_IMAGE: 'assets/backImage.svg',
  FAILED_ICON: 'assets/failedImage.svg',
  ADMIN_MORE_ICON: 'assets/adminmoreOprions.svg',
  CAREER_ICON: 'assets/careerDetailsImage.svg',
  CF_LOADER: 'assets/cf-loader.gif',
  UNIT_ICON: 'assets/unitsImage.svg',
  CALANDER_ICON: 'assets/icons/calender.svg',
  FAV_ICON: 'assets/love_fav.svg',
  HEART_ICON: 'assets/heart2.svg',
  CAREER_RATING: 'assets/career_rating.svg',
  CAREER_BOOK: 'assets/career_book.svg',
  CAREER_EDUCATION: 'assets/book.svg',
  CAREER_PLAY: 'assets/playvideo.svg',
  CAREER_PIGGY: 'assets/piggy.svg',
  CAREER_NOTES: 'assets/career_notes.svg',
  CAREER_RECTANGLE: 'assets/Rectangle_career.svg'
};

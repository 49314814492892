<div ngbDropdown class=" d-inline-block reportsProfile">
  <div class="" id="profileDropdown" ngbDropdownToggle>
    <!-- <img src="{{ IMAGES.PROFILE_PIC }}" class="reportsProfile__profilePic" /> -->
    <span class="align-middle userCharacter userCharacterInfo position-relative" routerLink="/editProfile/2">
      <span *ngIf="enableSymbol" class="position-absolute top-0 start-100 translate-middle rounded-circle p-2 notificationSymbol"></span>
      <span class="">{{userProfileCharacters}}</span>
    </span>
    <div class="reportsProfile__profileInfo">
      <div [ngClass]=" showScore ? 'name' : 'namescore'" class="extratext" ngbTooltip="{{ userName }}">{{userName}}</div>
      <div *ngIf="showScore" class="score">{{userScore}} <img src="{{IMAGES.INFO}}" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Reliability Score" /></div>
    </div>
    <img src="{{IMAGES.DOWN_ARROW}}" class="reportsProfile__arrow" />
  </div>
  <div ngbDropdownMenu aria-labelledby="profileDropdown" class="reportsProfile__dropDown">
    <span class="reportsProfile__dropDown__category" ngbDropdownItem (click)="goTo('editProfile/1')">My Profile</span>
    <span class="reportsProfile__dropDown__category" ngbDropdownItem (click)="goTo('editProfile/2')">Notifications <span *ngIf="notificationsCount > 0"
        class="reportsProfile__dropDown__category__subText ">{{notificationsCount}}</span></span>
    <!-- <span class="reportsProfile__dropDown__category" ngbDropdownItem (click)="goTo('editProfile/4')">My Plan Subscriptions</span> -->
    <span class="reportsProfile__dropDown__category" ngbDropdownItem (click)="goTo('editProfile/5')">Help/Report</span>
    <span class="reportsProfile__dropDown__category" ngbDropdownItem (click)="showShare=!showShare">Give Feedback</span>
    <!-- <span class="reportsProfile__dropDown__category" ngbDropdownItem>Refer friend</span> -->
    <span class="reportsProfile__dropDown__category" ngbDropdownItem (click)="logoutClearFuturz()">Logout</span>
    <img src="{{IMAGES.CURVED_YELLOW_PIC}}" class="reportsProfile__dropDown__topCurve" />
  </div>
  <cf-feedback *ngIf="showShare"(cancelOutput) ="cancelPopup($event)" class="feeback-default"></cf-feedback>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { API_CONSTANATS } from '../../../shared/api.constants';
import { HttpService } from '@app/services/http.service';


@Injectable({
  providedIn: 'root',
})
export class StudentDashboardService {
  apiBaseUrl = environment.serverUrl;
  constructor(private http: HttpClient,
    private httpService:HttpService) {}

  getDashboardList(data): Observable<any> {
    return of({
      reports: [
        {
          about: 'Get to know About',
          careerOne: 'My Career Options Report',
          careerTwo: '',
          reportOne: 'You should buy Career Options reports',
          reportTwo: ' ',
          key: 'Co1',
          isSelected: false,
          routingPath: '',
        },
        {
          about: 'Get to know About',
          careerOne: 'My Personality Report',
          careerTwo: '',
          reportOne: 'You should buy Personality reports',
          reportTwo: '',
          key: 'Cd',
          isSelected: false,
          routingPath: '',
        },
        {
          about: 'Get to know About',
          careerOne: 'My Greatest Strengths Report',
          careerTwo: '',
          reportOne: 'You should buy Greatest Strengths reports',
          reportTwo: '',
          key: 'Ygs',
          isSelected: false,
          routingPath: '',
        },
        {
          about: 'Get to know About',
          careerOne: 'My Traits and Definitions Report',
          careerTwo: '',
          reportOne: 'You should buy Traits and Definitions report',
          reportTwo: ' ',
          key: 'Td',
          isSelected: false,
          routingPath: '',
        },
        {
          about: 'Get to know About',
          careerOne: 'My Career Enjoyment Report',
          careerTwo: '',
          reportOne: 'You should buy Career Enjoyment reports',
          reportTwo: '',
          key: 'Cc',
          isSelected: false,
          routingPath: '',
        },
      ],
    });
  }
  getHashForPayment(data): Observable<any> {
    console.log("testtt")
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.PAYMENT_HASH}`, data,{headers:{"Accept":"text/html"},responseType:'text'});
  }
  getPaymentResponse(id): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.PAYMENT_RESPONSE}`+'/'+id);
  }
  getStudentAvailableReports(id): Observable<any> {
    let url= `${API_CONSTANATS.ADMIN.STUDENT_REPORT}/${id}`;
    return this.httpService.sendGet(url, true);
  }
  getstudentReportsList(id):Observable<any>{
    let url= `${API_CONSTANATS.ADMIN.STUDENT_REPORT_LIST}/${id}`;
    return this.httpService.sendGet(url, true);
  }
  getReports(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_REPORTS, true);
  }

}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../imageConstants';
import { ResourcesService } from '../../../services/resources.service';
import { StorageService } from '../../../services/storage.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cf-report-videos',
  templateUrl: './report-videos.component.html',
  styleUrls: ['./report-videos.component.scss']
})
export class ReportVideosComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  revideosData: any;
  mresults: any;
  reportID: string;
  // videosData: any = [
  //   [
  //     { time: '15:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //     { time: '1:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //   ],
  //   [
  //     { time: '5:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //     { time: '9:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //   ],
  //   [
  //     { time: '15:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //     { time: '1:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //   ],
  // ];
  constructor( private urlParams: ActivatedRoute,private resources: ResourcesService, private storage: StorageService, public sanitizer: DomSanitizer) {
    
  }

  ngOnInit(): void {
    this.urlParams.queryParams.subscribe((params) => {
      this.reportID = params.id;
      console.log("this.reportID",this.reportID)
    });
    const student = this.storage.getItem('userData');
    const data = { student_id: student.id,careerDetailID : this.reportID };
    if(this.reportID)
    {
      this.resources.getCareerVideos(data).subscribe((videosList: any) => {
        this.revideosData = videosList.videos;
        this.revideosData.forEach((ele) => {
          ele.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(ele.video_url);
        });
        this.mresults = [];
        const chunk_size = 1;
        while (this.revideosData.length > 0) {
          this.mresults.push(this.revideosData.splice(0, chunk_size));
        }
        console.log("videosData",this.revideosData)
        console.log("results",this.mresults)
      });
    }else{
     
    
      this.resources.getVideos(data).subscribe((videosList: any) => {
        this.revideosData = videosList;
        this.revideosData.forEach((ele) => {
          ele.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(ele.video_url);
        });
        this.mresults = [];
        const chunk_size = 1;
        while (this.revideosData.length > 0) {
          this.mresults.push(this.revideosData.splice(0, chunk_size));
        }
        console.log("videosData",this.revideosData)
        console.log("results",this.mresults)
      });
    }
   

  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_CONSTANATS } from '../../../shared/api.constants';
import { HttpService } from '@app/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiBaseUrl = environment.cmsUrl;
  constructor(private http: HttpClient, private httpService: HttpService) {}
  getAccountDetails(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.ACCOUNT_DETAILS, true);
  }
  updateAccountDetails(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_ACCOUNT_DETAILS, data, true);
  }
  updatePassword(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_SETTINGS_PASSWORD, data, true);
  }
  getSystemDetails(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_SYSTEM_SETTINGS, data, true);
  }
  updateSystemSettings(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_SYSTEM_SETTINGS, data, true);
  }
  getAdvancedSettings(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_ADVANCED_SETTINGS, data, true);
  }
  upddateAdvancedSettings(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_ADVANCED_SETTINGS, data, true);
  }
  getGenderSettings(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_GENDER_SETTINGS, data, true);
  }
  upddateGenderSettings(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_GENDER_SETTINGS, data, true);
  }
  getEmailTemplates(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_EMAIL_TEMPLATES, data, true);
  }
  insertEmailTemplate(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.INSERT_EMAIL_TEMPLATES, data, true);
  }
  getDepartments(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.BRANCH, data, true);
  }
  getUserList(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_USER_LIST, data, true);
  }
  insertInstituteUser(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.INSERT_INSTITUTE_USER, data, true);
  }
  getFeatures(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_FEATURES, true);
  }
  getUserById(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_INSTITUTE_USER_BY_ID, data, true);
  }
  updateUserById(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_INSTITUTE_USER, data, true);
  }
  deleteUserById(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.DELETE_INSTITUTE_USER, data, true);
  }
  getCustomerCareer(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_CUSTOMER_CAREER, true);
  }
  insertCustomerCareer(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.INSERT_CUSTOMER_CAREER, data, true);
  }
  getCareerCategories(): Observable<any> {
    return this.httpService.sendCMSGet(API_CONSTANATS.STUDENT.CMS_API.CAREER_CATEGORIES, false);
  }
  insertFeedback(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.INSERT_FEEDBACK, data, true);
  }
  getTransactionHistory(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.GET_TRANSACTION_HISTORY,data, true);
  }
  getTermsAndConditions(role): Observable<any> {
    let url=`${API_CONSTANATS.STUDENT.CMS_API.GET_TERMS_CONDITIONS}${role}`;
    return this.httpService.sendCMSGet(url, false);
  }
  getAboutSystem(): Observable<any> {
    let url=`${API_CONSTANATS.STUDENT.CMS_API.GET_ABOUT_SYSTEM}`;
    return this.httpService.sendCMSGet(url, false);
  }
  getPaymentTypes(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.PAYMENT_TYPES, true);
  }
  getProductTypes(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.PRODUCT_TYPES, true);
  }
  getWorksheet(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.ORDER_WORKSHEET,data, true);
  }
  updateOrderWorksheet(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.UPDATE_ORDER_WORKSHEET,data, true);
  }
  saveBranch(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.ADD_BRANCH, inputParams, true);
  }
  updateBranch(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.EDIT_BRANCH, inputParams, true);
  }
  getlistbranch(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.LIST_BRANCH, true);
  }
  
  getbranchDetails(id): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.BRANCH_DETAILS+'/'+id, true);
  }
  
  getallocatedUnits(id): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.ALLOCATED_UNITS+'/'+id, true);
  }
  requestUnits(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.REQUEST_UNITS, inputParams, true);
  }
  depositUnits(inputParams): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.DEPOSIT_UNITS, inputParams, true);
  }
  getCountries(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_COUNTRIES, true);
  }
  getStates(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_STATES, true);
  }
  getCities(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_CITIES, true);
  }

  getData(url): Observable<any> {
    return this.httpService.sendGet(url, true);
  }

  sendPostData(url, params): Observable<any> {
    return this.httpService.sendPost(url, params, true)
  }
  getNotifications(): Observable<any> {
    return this.httpService.sendGet(API_CONSTANATS.ADMIN.GET_NOTIFICATIONS, true);
  }
  viewedNotifications(data): Observable<any> {
    return this.httpService.sendPost(API_CONSTANATS.ADMIN.VIEWED_NOTIFICATIONS, data, true);
  }

  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { ReportsDropDownComponent } from './components/reports-drop-down/reports-drop-down.component';
import { ReportsProfileComponent } from './components/reports-profile/reports-profile.component';
import { ColorLogoComponent } from './components/color-logo/color-logo.component';
import { NormalLogoComponent } from './components/normal-logo/normal-logo.component';
import { ReportsFooterComponent } from './components/reports-footer/reports-footer.component';
import { SearchComponent } from './components/search/search.component';
import { BackArrowComponent } from './components/back-arrow/back-arrow.component';
import { ReportSecondSectionComponent } from './components/report-second-section/report-second-section.component';
import { NoDataFoundComponent } from './components/no-data-found/no-data-found.component';
import { RatingsComponent } from './components/ratings/ratings.component';
import { ReportsBooksComponent } from './components/reports-books/reports-books.component';
import { ReportVideosComponent } from './components/report-videos/report-videos.component';
import { CustomSpinnerComponent } from './components/custom-spinner/custom-spinner.component';
import { CareerSearchComponent } from './components/career-search/career-search.component';
import { BackArrowPlainComponent } from './components/back-arrow-plain/back-arrow-plain.component';
import { NotesComponent } from "./components/notes/notes.component";
import { ShareComponent } from "./components/share/share.component";
import { FeedbackComponent } from "@app/modules/admin/settings/feedback/feedback.component";
import { HttpClientModule } from '@angular/common/http';
import { AboutthissystemComponent } from '@app/modules/admin/settings/aboutthissystem/aboutthissystem.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
@NgModule({
  declarations: [
    MainFooterComponent,
    ReportsDropDownComponent,
    ReportsProfileComponent,
    NormalLogoComponent,
    ColorLogoComponent,
    ReportsFooterComponent,
    SearchComponent,
    BackArrowComponent,
    ReportSecondSectionComponent,
    NoDataFoundComponent,
    RatingsComponent,
    ReportsBooksComponent,
    ReportVideosComponent,
    CustomSpinnerComponent,
    CareerSearchComponent,
    BackArrowPlainComponent,
    NotesComponent,
    ShareComponent,
    FeedbackComponent,
    AboutthissystemComponent
  ],
  imports: [CommonModule, NgbModule, RouterModule, NgxSpinnerModule, FormsModule, ReactiveFormsModule,HttpClientModule,
    SlickCarouselModule, NgbPaginationModule],
  exports: [
    MainFooterComponent,
    ReportsDropDownComponent,
    ReportsProfileComponent,
    ColorLogoComponent,
    NormalLogoComponent,
    ReportsFooterComponent,
    SearchComponent,
    BackArrowComponent,
    ReportSecondSectionComponent,
    NoDataFoundComponent,
    RatingsComponent,
    ReportsBooksComponent,
    ReportVideosComponent,
    CustomSpinnerComponent,
    CareerSearchComponent,
    BackArrowPlainComponent,
    NotesComponent,
    ShareComponent,
    FeedbackComponent,
    AboutthissystemComponent,
    NgbPaginationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

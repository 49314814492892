import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, of } from 'rxjs';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  apiBaseUrl = environment.serverUrl;
  apiCMSBaseUrl = environment.cmsUrl;
  constructor(private http: HttpClient, private storageService: StorageService) { }

  sendPost(pageLink, inputParams, secure = false, header = null, responseType = null): Observable<any> {
    let headers;
    if (secure) {
      headers = this.setSecure();
    }   
    if (header) {
    }
    return this.http.post(`${this.apiBaseUrl}/${pageLink}`, inputParams, {
      headers,
      responseType,
    });
  }

  sendGet(pageLink, secure = false, header = null, responseType = null): Observable<any> {
    let headers;
    if (secure) {
      headers = this.setSecure();
    }
    return this.http.get(`${this.apiBaseUrl}/${pageLink}`, { headers, responseType });
  }
  sendCMSGet(pageLink, secure = false, header = null, responseType = null): Observable<any> {
    let headers;
    if (secure) {
      headers = this.setSecure();
    }
    return this.http.get(`${this.apiCMSBaseUrl}/${pageLink}`, { headers, responseType });
  }

  setSecure() {
    const headers: HttpHeaders = new HttpHeaders().set('X-Access-Token', `${this.storageService.getItem('token')}`);
    return headers;
  }
}

// let headers: HttpHeaders = new HttpHeaders();
// headers = headers.append('Accept', 'application/json');
// headers = headers.append('zumo-api-version', '2.0.0');
